import { getParentOfType, types } from 'mobx-state-tree';

import { ImageAddon } from '@xterm/addon-image';
import { Terminal } from 'xterm';
import { FitAddon } from 'xterm-addon-fit';
import { SearchAddon } from 'xterm-addon-search';
import { WebLinksAddon } from 'xterm-addon-web-links';

import debounce from 'lib/debounce';

import { ErrorStore } from 'components/forms/Input';
import { ITLookTerminalAddon, TERMINAL_FONT_SIZE } from 'pages/TerminalsModal/ITLookTerminalAddon';
import { TerminalFileDownloader, TerminalFileUploader } from 'pages/TerminalsModal/TerminalFileOperationsS';

import { Record } from 'stores/Instances/InventoryRecords';
import Store from 'stores/Store';

const TerminalAddons = types
  .model('TerminalAddons', {})
  .views((self) => ({
    get term() {
      return getParentOfType(self, TerminalConnection).term;
    },
    get app() {
      return getParentOfType(self, TerminalSession).app;
    },
  }))
  .volatile(() => ({
    addons: {},
  }))
  .actions((self) => ({
    getSearchAddon() {
      let searchAddon = self.addons.search;
      if (!searchAddon) {
        searchAddon = new SearchAddon();
        self.addons.search = searchAddon;
      }
      return searchAddon;
    },
    getFitAddon() {
      let fitAddon = self.addons.fit;
      if (!fitAddon) {
        fitAddon = new FitAddon();
        self.addons.fit = fitAddon;
      }
      return fitAddon;
    },
    getWebLinksAddon() {
      let linksAddon = self.addons.links;
      if (!linksAddon) {
        linksAddon = new WebLinksAddon();
        self.addons.links = linksAddon;
      }
      return linksAddon;
    },
    getImageAddon() {
      let imageAddon = self.addons.image;
      if (!imageAddon) {
        imageAddon = new ImageAddon();
        self.addons.image = imageAddon;
      }
      return imageAddon;
    },
    async createAttachAddon() {
      const attachAddon = new ITLookTerminalAddon(await self.app);
      await attachAddon.initSocket({
        onError: () => {
          getParentOfType(self, TerminalConnection).setLoadingInfo({
            loading: false,
            error: true,
            errorMsg: "Can't establish connection to hosts manager",
          });
        },
      });
      self.addons.attach = attachAddon;
      return attachAddon;
    },
    getAttachAddon() {
      return self.addons.attach;
    },
    getTerminalAddon() {
      return self.addons.attach;
    },
  }));

const TerminalConnection = types
  .model('TerminalConnection', {
    maxFontSize: types.optional(types.integer, TERMINAL_FONT_SIZE),
    initialized: types.optional(types.boolean, false),
    loading: types.optional(types.boolean, true),
    error: types.optional(types.boolean, false),
    errorMsg: types.maybeNull(types.string),
    addons: types.optional(TerminalAddons, () => TerminalAddons.create({})),
  })
  .volatile(() => ({
    term: null,
    resizeEventHandler: null,
    windowVisibilityChangeHandler: null,
  }))
  .views((self) => ({
    get session() {
      return getParentOfType(self, TerminalSession);
    },
    get record() {
      return self.session.record;
    },
  }))
  .actions((self) => ({
    beforeDetach: () => {
      if (self.resizeEventHandler) {
        window.removeEventListener('resize', self.resizeEventHandler);
        window.removeEventListener('orientationchange', self.resizeEventHandler);
      }
      if (self.windowVisibilityChangeHandler) {
        document.removeEventListener('visibilitychange', self.windowVisibilityChangeHandler);
      }
    },
    setTerminal: (term) => {
      self.term = term;
    },
    setLoadingInfo: ({ loading, error = false, errorMsg = '' }) => {
      self.loading = loading;
      self.error = error;
      self.errorMsg = errorMsg;
    },
    ensureTerminalCreated: () => {
      if (self.term) {
        return;
      }
      const term = new Terminal({
        allowProposedApi: true,
        convertEol: true,
        cols: self.session.record.session_1.xtermCols,
        rows: self.session.record.session_1.xtermRows,
        scrollback: 100000,
        allowTransparency: true,
        fontWeight: 400,
        fontFamily: 'Roboto Mono,Martian Mono,Menlo,Monaco,Consolas,monospace',
        fontSize: TERMINAL_FONT_SIZE,
        theme: {
          background: 'rgba(0, 0, 0, 0)',
        },
      });
      self.setTerminal(term);
      term.parser.registerOscHandler(0, (data) => {
        self.session.setXtermTitle(data.slice(data.indexOf(' ') + 1));
      });
    },

    ensureConnected: async () => {
      if (self.initialized) {
        return;
      }
      self.initialized = true;

      const elementId = self.session.id;
      const container = document.getElementById(elementId);
      self.term.open(container);

      self.resizeEventHandler = debounce(() => {
        const addons = self.addons;
        const oldRows = self.term.rows;
        const oldCols = self.term.cols;

        const propDim = addons.getFitAddon().proposeDimensions();

        if (propDim) {
          const fontRatio = (self.term.options.fontSize || self.maxFontSize) / self.maxFontSize;
          const newRows = Math.floor(propDim.rows * fontRatio);
          const newCols = Math.floor(propDim.cols * fontRatio);
          if (newRows !== oldRows || newCols !== oldCols) {
            addons.getAttachAddon().setTerminalSize(newRows, newCols);
          }
        }
      }, 50);

      self.windowVisibilityChangeHandler = () => {
        if (document.visibilityState === 'visible') {
          self.term.options.fontSize = self.maxFontSize;
          self.resizeEventHandler();
        }
      };

      const fitAddon = self.addons.getFitAddon();
      self.term.loadAddon(fitAddon);
      self.term.loadAddon(self.addons.getWebLinksAddon());
      self.term.loadAddon(self.addons.getImageAddon());
      self.term.loadAddon(self.addons.getSearchAddon());

      window.addEventListener('resize', self.resizeEventHandler);
      window.addEventListener('orientationchange', self.resizeEventHandler);
      document.addEventListener('visibilitychange', self.windowVisibilityChangeHandler);

      const attachAddon = await self.addons.createAttachAddon();
      self.term.loadAddon(attachAddon);
      await attachAddon.waitUntilActivated();

      try {
        await attachAddon.connect({
          sessionId: self.session.id,
          proposeDimensions: () => {
            return fitAddon.proposeDimensions();
          },
          resizeTerminal: () => {
            self.resizeEventHandler();
          },
        });

        self.setLoadingInfo({ loading: false });
      } catch (error) {
        self.setLoadingInfo({ loading: false, error: true, errorMsg: error.toString() });
        console.log(error);
      }
    },
  }));

const TerminalSession = types
  .model('TerminalSession', {
    record: types.maybeNull(types.reference(Record)),
    target: types.maybeNull(types.reference(Record)),
    xtermTitle: types.maybeNull(types.string),
    sessionId: types.identifier,
    connection: types.optional(TerminalConnection, () => TerminalConnection.create({})),
    fileDownloader: types.optional(TerminalFileDownloader, () =>
      TerminalFileDownloader.create({
        error: ErrorStore.create({}),
      })
    ),
    fileUploader: types.optional(TerminalFileUploader, () =>
      TerminalFileUploader.create({
        // do we use error setter here?!
        error: ErrorStore.create({}),
      })
    ),
    confirmCloseVisible: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get id() {
      return self.sessionId;
    },
    get loaded() {
      return !!self.target;
    },
    get isSelected() {
      return getParentOfType(self, TerminalsStore).isSelected(self);
    },
    get accessUser() {
      return self.target.host_1.accessUser;
    },
    get accessHost() {
      return self.target.host_1.hostname;
    },
    get app() {
      return Store.instance.Applications.getById(self.record.root_1.app);
    },
  }))

  .actions((self) => ({
    afterAttach: async () => {
      // create session using session record
      const getById = Store.instance.InventoryRecords.getByIdAsync;
      self.updateTargetRecord(await getById(self.record.session_1.target));
    },
    updateTargetRecord: (target) => {
      self.target = target;
    },

    setXtermTitle: (title) => {
      self.xtermTitle = title;
    },

    showConfirmClose: () => {
      self.confirmCloseVisible = true;
    },
    hideConfirmClose: () => {
      self.confirmCloseVisible = false;
    },
    onSelect: () => {
      self.connection.ensureTerminalCreated();
    },
    close: async () => {
      const appApi = await (await self.app).api();
      await appApi.post('/api/v1/terminal/remove', { id: self.id });
    },
  }));

const TerminalsModalStore = types
  .model('TerminalsModalStore', {
    visible: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    show: () => {
      getParentOfType(self, TerminalsStore).ensureDefaultSession();
      self.visible = true;
    },
    hide: () => {
      self.visible = false;
    },
  }));

const TerminalsStore = types
  .model('TerminalsStore', {
    modal: types.optional(TerminalsModalStore, () => TerminalsModalStore.create({})),
    sessions: types.map(TerminalSession),
    selectedSession: types.maybeNull(types.reference(TerminalSession)),
    refreshIntervalId: types.maybeNull(types.integer),
    doNotTrackSessions: types.map(types.boolean),
    sessionRefreshInProgress: false,
    lastNotifiedError: 0,
  })
  .views((self) => ({
    get sortedSessions() {
      return Array.from(self.sessions.values()).sort(
        (a, b) => a.record.versionable_1.createdAt - b.record.versionable_1.createdAt
      );
    },
    isSelected: (session) => {
      return self.selectedSession && self.selectedSession.id === session.id;
    },
  }))
  .actions((self) => ({
    afterAttach: () => {
      // setting must happen here and not in start() method, as start is only
      // called when we start new terminal
      self.refreshIntervalId = setInterval(self.loadSessionsFromAPI, 2000);
    },
    beforeDetach: () => {
      if (self.refreshIntervalId) {
        clearInterval(self.refreshIntervalId);
      }
    },
    updateSessions: ({ sessions, sessionsIds }) => {
      // remove obsolete sessions
      Array.from(self.sessions.values()).forEach((el) => {
        if (!sessionsIds.has(el.id) && !self.doNotTrackSessions.has(el.id)) {
          self.removeSessionRecord(el);
        }
      });

      // add missing sessions
      sessions.forEach((session) => {
        if (!self.sessions.has(session.id) && !self.doNotTrackSessions.has(session.id)) {
          self.sessions.set(
            session.id,
            TerminalSession.create({
              sessionId: session.id,
              record: session,
            })
          );
        }
      });
      self.sessionRefreshInProgress = false;
    },
    lockTrackingSession: (sessionId) => {
      self.doNotTrackSessions.set(sessionId, true);
    },
    unlockTrackingSession: (sessionId) => {
      self.doNotTrackSessions.delete(sessionId);
    },
    setLastNotifiedError(value) {
      self.lastNotifiedError = value;
      self.sessionRefreshInProgress = false;
    },
    loadSessionsFromAPI: async () => {
      if (!Store.fullyLoaded || self.sessionRefreshInProgress) {
        return;
      }
      self.sessionRefreshInProgress = true;
      try {
        const { records, recordsIds } = await Store.instance.InventoryRecords.search_v2({
          query: [
            "inherits('std::host/Session:1')",
            "std::host/Session:1.state == 'open'",
            "std::types/Versionable:1.status != 'archived'",
            `std::host/Session:1.user IN id('${Store.Profile.user.email}')`,
          ].join(' AND '),
          start: 0,
          size: 100,
        });

        self.updateSessions({
          sessions: records,
          sessionsIds: recordsIds,
        });
      } catch (e) {
        const now = Date.now();
        // do not flood with errors. send new message each 30s
        if (self.lastNotifiedError - now > 30000) {
          Store.Notifications.error(['Failed to load active session from API', e.toString()]);
        }
        self.setLastNotifiedError(now);
        return;
      }
      self.setLastNotifiedError(0);
    },
    ensureDefaultSession: () => {
      if (!self.selectedSession) {
        self.selectDefaultSession();
      }
    },
    selectDefaultSession: () => {
      const session = self.sortedSessions[0] || null;
      if (session) {
        self.selectSession(session);
      } else {
        self.modal.hide();
      }
    },
    selectSession: (session) => {
      self.selectedSession = session;
      self.selectedSession.onSelect();
    },
    removeSessionRecord: (session) => {
      if (session === self.selectedSession) {
        self.selectedSession = null;
      }
      self.sessions.delete(session.id);
      self.selectDefaultSession();
    },
    closeSession: async (session) => {
      self.lockTrackingSession(session.id);
      await session.close();
      self.removeSessionRecord(session);
      // unlock only in a minute to make sure that session tracker
      // won't return it
      setTimeout(() => self.unlockTrackingSession(session.id), 60000);
    },
    start: () => {
      self.loadSessionsFromAPI();
    },
    updateSession: (session) => {
      if (!self.sessions.has(session.id)) {
        self.sessions.set(
          session.id,
          TerminalSession.create({
            sessionId: session.id,
            record: session,
          })
        );
      }
    },

    loadSessionFromAPI: async (sessionId) => {
      self.updateSession(await Store.instance.InventoryRecords.getByIdAsync(sessionId));
    },
    startNewSession: async (target) => {
      const app = await Store.instance.Applications.getById(target.root_1.app);
      const appApi = await app.api();

      const terminalResp = await appApi.post('/api/v1/terminal/create', {
        id: target.root_1.id,
        window: {
          width: 80,
          height: 40,
        },
      });
      const sessionId = terminalResp.data.data.session_id;
      self.lockTrackingSession(sessionId);
      await self.loadSessionFromAPI(sessionId);
      self.selectSession(sessionId);
      // ugly but most likely working solution
      // we should not update newly created session for at least 5
      // seconds, to avoid race condition.
      setTimeout(() => self.unlockTrackingSession(sessionId), 5000);
      self.modal.show();
    },
  }));

export { TerminalSession, TerminalsStore };
