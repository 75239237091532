import React from 'react';
import { observer } from 'mobx-react';
import QRCode from 'react-qr-code';
import Checkbox from 'components/Checkbox';
import Input from 'components/forms/Input';
import Icon from 'components/Icon';
import Notifications from 'components/Notifications';
import PageLayout from 'pages/Auth/components/PageLayout';
var TermsAndConditionsCheckbox = observer(function (_a) {
    var store = _a.store;
    return (React.createElement("div", { className: "form-box" },
        React.createElement("fieldset", { className: "fieldset" },
            React.createElement(Checkbox, { label: "I have read and agree to terms and conditions.", checked: store.agreementCheckboxSelected, disabled: store.processing, onChange: store.toggleAgreementCheckBox }))));
});
var TermsAndConditions = observer(function (_a) {
    var store = _a.store;
    if (!store.agreementText || store.totpSecret) {
        return null;
    }
    return (React.createElement(PageLayout, { title: "Terms and Conditions agreement", loginBoxClassName: "license-box" },
        React.createElement("div", { className: "license-container" }, store.agreementText),
        React.createElement(TermsAndConditionsCheckbox, { store: store }),
        React.createElement("form", null,
            React.createElement("div", { className: "form-box-line" },
                React.createElement("div", { className: "form-item" },
                    React.createElement("div", { className: "item-container" },
                        React.createElement("button", { key: "agree", type: "button", className: "btn btn-primary", onClick: store.signAgreement, disabled: !store.agreementCheckboxSelected || store.processing }, "Submit"))),
                React.createElement("div", { className: "form-item" },
                    React.createElement("div", { className: "item-container" },
                        React.createElement("button", { key: "abort", type: "button", className: "btn btn-default", onClick: window.location.reload, disabled: store.processing }, "Cancel")))))));
});
var TOTPSetup = observer(function (_a) {
    var store = _a.store;
    if (!store.totpSecret) {
        return null;
    }
    return (React.createElement("div", { className: "grid login" },
        React.createElement(Notifications, null),
        React.createElement("div", { className: "content" },
            React.createElement("canvas", { className: "login-animated-container" }),
            React.createElement("div", { className: "modal multifactor" },
                React.createElement("div", { className: "modal-box" },
                    React.createElement("div", { className: "modal-header" },
                        React.createElement("div", { className: "title" }, "Multi-factor authentication setup"),
                        React.createElement("div", { className: "action" },
                            React.createElement(Icon, { className: "close-dark", onClick: function () { return !store.processing && store.onSuccessLogin(store.token); } }))),
                    React.createElement("div", { className: "modal-body" },
                        React.createElement("div", { className: "qrcode-container" },
                            React.createElement("div", { className: "left" },
                                React.createElement("div", { className: "qrcode" },
                                    React.createElement(QRCode, { value: store.totpSecret }))),
                            React.createElement("div", { className: "right" },
                                React.createElement("div", null, "Multi-factor authentication is a crucial part of your secure access to the ITLook system. To finish the setup of time-based one-time password (TOTP), please enter a code from your TOTP-application."),
                                React.createElement("div", null,
                                    React.createElement("div", null, "Enter a code from your TOTP-application"),
                                    React.createElement("div", { className: "form-box-line" },
                                        React.createElement(Input, { store: store.totpCode }),
                                        React.createElement("div", { className: "form-item" },
                                            React.createElement("div", { className: "item-container" },
                                                React.createElement("button", { key: "agree", type: "button", className: "btn btn-primary", onClick: store.confirmTotpSecret, disabled: !store.totpCode.value || store.processing }, "Submit")))))))),
                    React.createElement("div", { className: "modal-footer" },
                        React.createElement("div", { className: "error-placeholder" },
                            React.createElement("div", { className: "error text-danger" }, store.error))))))));
});
export default observer(function (_a) {
    var store = _a.store;
    if (!store.firstLogin) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(TermsAndConditions, { store: store }),
        React.createElement(TOTPSetup, { store: store })));
});
