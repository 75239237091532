var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useRef, useState } from 'react';
// @ts-ignore: asciinema-player does not have type declaration
import * as AsciinemaPlayerLibrary from 'asciinema-player';
import { useWindowDimensions } from 'lib/windowSize';
var defaultProps = {
    // NOTE(andreykurilin): All values `!= false` result in taking the whole
    //  page by player. I tried to wrap it
    fit: 'both',
    controls: true,
    loop: false,
};
// an adopted version of https://github.com/asciinema/asciinema-player/issues/72#issuecomment-1024929693
var AsciinemaPlayer = function (props) {
    var ref = useRef(null);
    var _a = useWindowDimensions(), windowHeight = _a.height, windowWidth = _a.width;
    var _b = useState(windowHeight), height = _b[0], setHeight = _b[1];
    useEffect(function () {
        if (ref.current) {
            // reducing height is creating padding on bottom, that is needed
            // to display everything nicely
            setHeight(windowHeight - ref.current.getBoundingClientRect().top - 60);
        }
    }, [ref.current, windowHeight, windowWidth]);
    useEffect(function () {
        // NOTE(andreykurilin): The following function is described at asciinema-player.dist.index.js
        //    and it has a weird input argument spec: function create(src, elem)
        //    The weird thing is a first line of the function which is hack to
        //    allow the third argument to be passed:
        //      let opts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
        AsciinemaPlayerLibrary.create({ data: props.data }, ref.current, __assign(__assign({}, props), defaultProps));
    }, []);
    return React.createElement("div", { ref: ref, style: { height: height } });
};
export default AsciinemaPlayer;
