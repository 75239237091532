import { types } from 'mobx-state-tree';
import * as path from 'path';
import { ErrorStore, FilePathInputStore, InputStore, NoDisplayError, TagsInputStore } from 'components/forms/Input';
import DropdownStore from 'components/forms/Input/DropdownSelectorS';
import TextEditorS, { Syntaxes, SyntaxOptions } from 'components/forms/TextEditorS';
import Store from 'stores/Store';
var SyntaxFromCommandMap = {
    python: Syntaxes.python,
    python3: Syntaxes.python,
    sh: Syntaxes.bash,
    bash: Syntaxes.bash,
};
export var NewScriptStore = types
    .model('NewScriptStore', {
    name: types.optional(FilePathInputStore, function () {
        return FilePathInputStore.create({
            inputType: 'text',
            label: 'Name',
            placeholder: 'my-awesome-script.sh',
            autoFocus: true,
        });
    }),
    command: types.optional(InputStore, function () {
        return InputStore.create({
            label: 'Executor (command)',
            placeholder: 'Enter an executable for the script',
            onChangeHandlingDelay: 0,
            autocompleteOptions: ['python3', 'bash'],
            error: NoDisplayError.create(),
        });
    }),
    description: types.optional(InputStore, function () {
        return InputStore.create({
            label: 'Description',
            placeholder: "For example, it can be something like 'The script that should make me happy'.",
            error: NoDisplayError.create(),
        });
    }),
    userChoseSyntax: false,
    syntaxSelector: types.optional(DropdownStore, function () {
        return DropdownStore.create({
            label: 'Syntax',
            options: Object.entries(SyntaxOptions).map(function (_a) {
                var syntax = _a[0], cfg = _a[1];
                return ({ value: syntax, title: cfg.title });
            }),
        });
    }),
    syntaxFromFileType: types.maybeNull(types.string),
    syntaxFromCommand: types.maybeNull(types.string),
    labels: types.optional(TagsInputStore, function () {
        return TagsInputStore.create({
            label: 'Labels',
            placeholder: 'Add new label',
            error: ErrorStore.create({}),
        });
    }),
    code: types.optional(TextEditorS, function () {
        return TextEditorS.create({
            label: 'Code',
            displayLineNumbers: true,
        });
    }),
    inProgress: false,
    globalError: types.maybeNull(types.string),
    scriptID: types.maybeNull(types.string),
})
    .volatile(function () { return ({
    instance: null,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSuccess: function (scriptID, scriptName) { },
}); })
    .views(function (self) { return ({
    get submitButtonTitle() {
        return 'Create';
    },
    get pageSubtitle() {
        return 'Create new';
    },
    get redirectOnCancel() {
        return self.instance ? "/i/".concat(self.instance.name, "/scripts") : '/';
    },
    get submitSuccessMessage() {
        return "Script ".concat(self.name.value, " is created.");
    },
    get syntax() {
        return ((self.userChoseSyntax && self.syntaxSelector.selected) || self.syntaxFromCommand || self.syntaxFromFileType);
    },
    isReady: function () {
        return self.name.isDone() && this.syntax && self.command.isDone() && self.code.value && self.labels.isDone();
    },
}); })
    .actions(function (self) { return ({
    linkInstanceStore: function (store) {
        self.instance = store;
    },
    registerOnSuccess: function (func) {
        self.onSuccess = func;
    },
    onNameChange: function (value) {
        if (!self.userChoseSyntax) {
            var parsedPath = path.parse(value);
            self.code.setFileName(parsedPath.base);
            var fileType_1 = parsedPath.ext.slice(1);
            var maybeSyntax = Object.keys(Syntaxes).filter(function (syntax) {
                return SyntaxOptions[syntax].fileTypes.includes(fileType_1);
            });
            if (maybeSyntax.length === 1) {
                self.syntaxFromFileType = maybeSyntax[0];
            }
            else {
                self.syntaxFromFileType = null;
            }
            self.syntaxSelector.handleSelect(self.syntax ? self.syntax : undefined, false);
            self.code.setSyntax(self.syntax ? self.syntax : undefined);
        }
    },
    onScriptNameInputFocusOut: function () {
        if (!self.name.value) {
            self.name.setError('Name of the script is required field.');
        }
    },
    setSyntaxByUser: function (value) {
        self.userChoseSyntax = value !== undefined;
        self.code.setSyntax(self.syntax ? self.syntax : undefined);
    },
    onCommandChange: function (command) {
        var maybeSyntax = null;
        Object.entries(SyntaxFromCommandMap).some(function (_a) {
            var c = _a[0], s = _a[1];
            if (command.startsWith(c)) {
                maybeSyntax = s;
            }
            return maybeSyntax !== null;
        });
        self.syntaxFromCommand = maybeSyntax;
        self.syntaxSelector.handleSelect(self.syntax ? self.syntax : undefined, false);
        self.code.setSyntax(self.syntax ? self.syntax : undefined);
    },
    // other
    setInProgress: function (value) {
        self.inProgress = value;
        self.name.setDisabled(value);
        self.command.setDisabled(value);
        self.labels.setDisabled(value);
        self.description.setDisabled(value);
        self.code.setReadOnly(value);
    },
    onFailure: function (response, errors) {
        self.globalError = errors.join(' ');
        this.setInProgress(false);
    },
    handleOnSuccess: function (response, responseData) {
        Store.Notifications.success(self.submitSuccessMessage);
        self.scriptID = responseData.data['std::types/Root:1'].id;
        self.onSuccess(self.scriptID, self.name.value);
        this.setInProgress(false);
    },
    onSubmit: function (e) {
        var _this = this;
        e.stopPropagation();
        if (!self.isReady()) {
            return;
        }
        self.globalError = null;
        this.setInProgress(true);
        self.instance.TransportLayer.post({
            url: 'i/api/v1/scripts',
            body: {
                path: path.normalize(self.name.value),
                labels: self.labels.items,
                command: [self.command.value],
                syntax: self.syntax,
                code: self.code.value.split('\n'),
                config: '{}',
                description: self.description.value || '',
            },
            onFailure: this.onFailure,
            onSuccess: function (response, responseData) { return _this.handleOnSuccess(response, responseData); },
        });
    },
    afterCreate: function () {
        self.name.registerOnFocusOutHandler(this.onScriptNameInputFocusOut);
        self.name.registerOnChangeHandler(this.onNameChange);
        self.command.registerOnChangeHandler(this.onCommandChange);
        self.syntaxSelector.registerOnSelectHandler(this.setSyntaxByUser);
    },
}); });
export var EditScriptStore = NewScriptStore.named('EditScriptStore')
    .props({
    originalName: types.string,
    originalDescription: types.string,
    originalCommand: types.string,
    originalSyntax: types.string,
    originalLabels: types.array(types.string),
    originalCode: types.string,
})
    .views(function (self) { return ({
    get submitButtonTitle() {
        return 'Update';
    },
    get pageSubtitle() {
        return "Update '".concat(self.originalName.split('/').pop(), "'");
    },
    get redirectOnCancel() {
        return "/i/".concat(self.instance.name, "/records/").concat(self.scriptID);
    },
    get submitSuccessMessage() {
        return "Script ".concat(self.name.value, " is changed.");
    },
    get changedValues() {
        var changes = {};
        if (self.command.value !== self.originalCommand) {
            changes.command = [self.command.value];
        }
        if (self.description.value !== self.originalDescription) {
            changes.description = self.description.value;
        }
        if (self.syntaxSelector.selected !== self.originalSyntax) {
            changes.syntax = self.syntaxSelector.selected;
        }
        if (self.code.value !== self.originalCode) {
            changes.code = self.code.value.split('\n');
        }
        if (self.labels.items.length !== self.originalLabels.length ||
            !self.labels.items.every(function (value, index) { return value === self.originalLabels[index]; })) {
            changes.labels = self.labels.items;
        }
        return changes;
    },
    isReady: function () {
        return (self.syntaxSelector.selected &&
            self.command.isDone() &&
            self.code.value &&
            self.labels.isDone() &&
            JSON.stringify(this.changedValues) !== '{}');
    },
}); })
    .actions(function (self) { return ({
    afterCreate: function () {
        self.name = FilePathInputStore.create({
            inputType: 'text',
            label: 'Name',
            autoFocus: false,
            value: self.originalName,
            disabled: true,
            ignoreSetDisabledCalls: true,
        });
        self.description.setValue(self.originalDescription);
        self.command.setValue(self.originalCommand);
        self.syntaxSelector.handleSelect(self.originalSyntax);
        self.labels.setItems(Array.from(self.originalLabels));
        self.code.setValue(self.originalCode);
    },
    onSubmit: function (e) {
        e.stopPropagation();
        if (!self.isReady()) {
            return;
        }
        self.globalError = null;
        self.setInProgress(true);
        self.instance.TransportLayer.put({
            url: "i/api/v1/scripts/".concat(self.scriptID),
            body: self.changedValues,
            onFailure: self.onFailure,
            onSuccess: function (response, responseData) { return self.handleOnSuccess(response, responseData); },
        });
    },
}); });
