import * as queryString from 'lib/query-string';
import { UUID4 } from 'lib/uuid-utils';
var simpleRequest = function (_a) {
    var url = _a.url, method = _a.method, query = _a.query, body = _a.body, authToken = _a.authToken, onFailure = _a.onFailure, onSuccess = _a.onSuccess, onFinish = _a.onFinish, onCrash = _a.onCrash, redirectStrategy = _a.redirectStrategy;
    var requestID = UUID4();
    var headers = {
        'ITLook-Request-ID': requestID,
    };
    if (authToken) {
        headers.Authorization = "Bearer ".concat(authToken);
    }
    var options = { method: method };
    options.headers = headers;
    if (options.method !== 'GET' && body !== undefined) {
        // array is also good for us here...
        if (typeof body === 'object' && body !== null) {
            options.body = JSON.stringify(body);
            options.headers['Content-Type'] = 'application/json';
        }
        else {
            options.body = body;
        }
    }
    if (redirectStrategy) {
        options.redirect = redirectStrategy;
    }
    if (query) {
        url = "".concat(url, "?").concat(queryString.stringify(query));
    }
    // eslint-disable-next-line no-console
    console.debug("Request '".concat(method, " ").concat(url, "' [req-id=").concat(requestID, "]"));
    fetch(url, options)
        .then(function (res) {
        // eslint-disable-next-line no-console
        console.debug(res);
        res
            .json()
            .then(function (data) {
            if (res.status >= 500 && onCrash !== undefined) {
                var errorMessage = data.errors ? data.errors.join('\n') : JSON.stringify(data);
                onCrash(res, new Error(errorMessage));
            }
            else if (data.hasOwnProperty('errors') && data.errors !== null && data.errors.length > 0) {
                if (onFailure !== undefined) {
                    onFailure(res, data.errors, data);
                }
            }
            else if (res.status !== 200) {
                if (onFailure !== undefined) {
                    onFailure(res, ['Response status code is not 200.']);
                }
            }
            else if (onSuccess !== undefined) {
                onSuccess(res, data);
            }
        })
            .catch(function (error) {
            if (error.stopPropagation !== undefined) {
                error.stopPropagation();
            }
            if (onCrash !== undefined) {
                onCrash(res, error);
            }
        })
            .finally(function () {
            if (onFinish !== undefined) {
                onFinish();
            }
        });
    })
        .catch(function (error) {
        // this should avoid crashing of app
        // eslint-disable-next-line no-console
        console.error(error);
    });
};
var requests = {
    GET: function (_a) {
        var url = _a.url, query = _a.query, authToken = _a.authToken, onFailure = _a.onFailure, onSuccess = _a.onSuccess, onFinish = _a.onFinish, onCrash = _a.onCrash, redirectStrategy = _a.redirectStrategy;
        return simpleRequest({
            url: url,
            method: 'GET',
            query: query,
            body: undefined,
            authToken: authToken,
            onFailure: onFailure,
            onSuccess: onSuccess,
            onFinish: onFinish,
            onCrash: onCrash,
            redirectStrategy: redirectStrategy,
        });
    },
    POST: function (_a) {
        var url = _a.url, query = _a.query, body = _a.body, authToken = _a.authToken, onFailure = _a.onFailure, onSuccess = _a.onSuccess, onFinish = _a.onFinish, onCrash = _a.onCrash, redirectStrategy = _a.redirectStrategy;
        return simpleRequest({
            url: url,
            method: 'POST',
            query: query,
            body: body,
            authToken: authToken,
            onFailure: onFailure,
            onSuccess: onSuccess,
            onFinish: onFinish,
            onCrash: onCrash,
            redirectStrategy: redirectStrategy,
        });
    },
    PUT: function (_a) {
        var url = _a.url, query = _a.query, body = _a.body, authToken = _a.authToken, onFailure = _a.onFailure, onSuccess = _a.onSuccess, onFinish = _a.onFinish, onCrash = _a.onCrash, redirectStrategy = _a.redirectStrategy;
        return simpleRequest({
            url: url,
            method: 'PUT',
            query: query,
            body: body,
            authToken: authToken,
            onFailure: onFailure,
            onSuccess: onSuccess,
            onFinish: onFinish,
            onCrash: onCrash,
            redirectStrategy: redirectStrategy,
        });
    },
    DELETE: function (_a) {
        var url = _a.url, query = _a.query, body = _a.body, authToken = _a.authToken, onFailure = _a.onFailure, onSuccess = _a.onSuccess, onFinish = _a.onFinish, onCrash = _a.onCrash, redirectStrategy = _a.redirectStrategy;
        return simpleRequest({
            url: url,
            method: 'DELETE',
            query: query,
            body: body,
            authToken: authToken,
            onFailure: onFailure,
            onSuccess: onSuccess,
            onFinish: onFinish,
            onCrash: onCrash,
            redirectStrategy: redirectStrategy,
        });
    },
};
export default requests;
