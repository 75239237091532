import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { inject, observer } from 'mobx-react';

import { formatHostInfo } from 'lib/hosts';

import { SimpleFormItem } from 'components/forms/FormItem';
import { SimpleTextViewer, Syntaxes } from 'components/forms/TextEditor';
import { Spinner } from 'components/Loader';
import { Table } from 'components/table/Table';
import {
  ColumnCustom,
  ColumnLabels,
  ColumnModel,
  ColumnRecordLink,
  ColumnTimestamp,
  loadRecordsWithStatusStats,
  VerTableStore,
} from 'components/table/TableS';

import Store from 'stores/Store';

const ScriptRunner = inject(
  'instance',
  'store'
)(
  observer((props) => {
    const navigate = useNavigate();

    const [tableStore] = useState(
      VerTableStore.create({
        paginationDefaultRowsPerPage: 5,
        paginationRowsPerPageOptions: [5, 10, 15, 50, 100],
        selectable: true,
        query:
          "(inherits('std::host/Container:1') OR inherits('std::host/Host:1')) AND std::types/Statusable:1.status == 'ok'",
        columns: [
          ColumnModel.create({}),
          ColumnRecordLink.create({ name: 'Name', key: 'std::types/Root:1.id' }),
          ColumnCustom.create({ name: 'Access' }).setRender((row) => {
            const hostData = row.data.get('std::host/Host:1');
            const containerData = row.data.get('std::host/Container:1');
            const { userAtIP } = formatHostInfo(hostData, containerData);
            return userAtIP;
          }),
          ColumnLabels.create({ name: 'Labels', key: 'std::types/Root:1.labels' }),
          ColumnTimestamp.create({ name: 'Updated', key: 'std::types/Versionable:1.updatedAt' }),
        ],
      })
    );

    useEffect(() => {
      loadRecordsWithStatusStats(tableStore);
      tableStore.setUp({ instance: props.instance, transport: props.store.TransportLayer });
    }, []);

    const [showForm, setShowForm] = useState(false);
    const [requestIsGone, setRequestIsGone] = useState(false);

    if (!showForm) {
      return (
        <form>
          <button type="button" className="btn btn-primary" onClick={() => setShowForm(true)}>
            Run Script
          </button>
        </form>
      );
    }

    const onSubmit = async () => {
      setRequestIsGone();
      const apis = new Map();

      const scriptPromises = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const [host_id, host] of tableStore.selectedRows) {
        const appId = host.data.get('std::types/Root:1').get('app');
        if (!apis.has(appId)) {
          // eslint-disable-next-line no-await-in-loop
          const app = await Store.instance.Applications.getById(appId);
          // eslint-disable-next-line no-await-in-loop
          apis.set(appId, await app.api());
        }
        const body = {
          script_id: props.record.root_1.id,
          script_version: props.record.versionable_1.version,
          code_app_id: props.record.root_1.app,
          host_id: host_id,
          script_args: {},
        };

        scriptPromises.push(apis.get(appId).post('/api/v1/script/run', body));
      }

      const scriptRuns = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const script of scriptPromises) {
        // eslint-disable-next-line no-await-in-loop
        const res = await script;
        scriptRuns.push(res.data.data.script_run);
      }

      if (scriptRuns.length === 1) {
        navigate(`/i/${props.instance.name}/records/${scriptRuns[0]['std::types/Root:1'].id}`);
      } else {
        // timeout is needed as search might not return results at the right moment
        setTimeout(() => navigate(`/i/${props.instance.name}/records/${props.record.root_1.id}/script-runs`), 1000);
      }
    };

    return (
      <form>
        <h3>Script run parameters</h3>
        <span>Select host(s) to run the script.</span>

        <Table store={tableStore} />

        <div className="form-box">
          <SimpleFormItem>
            <button
              type="button"
              className="btn btn-success"
              onClick={onSubmit}
              disabled={!tableStore.selectedRows.size || requestIsGone}
            >
              Run it
            </button>
            <button
              type="button"
              className="btn btn-default"
              onClick={() => setShowForm(false)}
              disabled={requestIsGone}
            >
              Cancel
            </button>
          </SimpleFormItem>
        </div>
      </form>
    );
  })
);

const ScriptTab = inject('instance')(
  observer((props) => {
    const itIsScriptRunView = Boolean(props.record.script_run_1);
    let scriptRecord;
    if (itIsScriptRunView) {
      scriptRecord = props.record.script_run_1.scriptObj;
    } else {
      scriptRecord = props.record;
    }

    if (!scriptRecord.loaded) {
      return <Spinner />;
    }

    return (
      <div className="form-box">
        <SimpleTextViewer
          label={`Code [syntax: ${scriptRecord.script_1.syntax}]`}
          syntax={Syntaxes.hasOwnProperty(scriptRecord.script_1.syntax) ? scriptRecord.script_1.syntax : Syntaxes.plain}
          value={scriptRecord.script_1.code.join('\n')}
          filename={scriptRecord.fs_node_1.name}
        />
        {!itIsScriptRunView && <ScriptRunner record={scriptRecord} />}
      </div>
    );
  })
);

ScriptTab.suites = (record) => record.script_1 || record.script_run_1;

export default ScriptTab;
