import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { ApplicationStates, HOST_APP_TYPE } from 'Constants';
import Input from 'components/forms/Input';
import Icon from 'components/Icon';
import { Spinner } from 'components/Loader';
import { ConfirmationAboutDeleteWindow } from 'components/ModalWindow';
import { InstanceLink } from 'components/Nav';
import { ContainerBox, PageHeader } from 'components/Page';
import { Table } from 'components/table/Table';
import { ColumnModel, ColumnRecordLink, ColumnReferences, ColumnText, ColumnTimestamp, loadRecordsWithStatusStats, VerTableStore, } from 'components/table/TableS';
import HostsManagersListS from 'pages/Instances/HostsManager/HostsManagersListS';
var NameFilter = inject('instance')(observer(function (props) {
    var name = useParams().name;
    var navigate = useNavigate();
    useEffect(function () {
        props.pageS.applyNameFilter(name ? name.toLowerCase() : '');
        navigate("/i/".concat(props.instance.name, "/hosts-managers?").concat(props.pageS.queryString));
    }, [name]);
    if (!props.instance.Applications.loaded) {
        return null;
    }
    return (React.createElement("div", { className: "form-box" },
        React.createElement(Input, { store: props.pageS.nameFilter })));
}));
var StateFilter = inject('instance')(observer(function (props) {
    var navigate = useNavigate();
    var filterStatus = function (eventKey) {
        props.pageS.applyStateFilter(eventKey.target.name, eventKey.target.checked);
        navigate("/i/".concat(props.instance.name, "/hosts-managers?").concat(props.pageS.queryString));
    };
    return (React.createElement("form", { className: "form-box" },
        React.createElement("div", { className: "btn-group" }, Object.entries(ApplicationStates).map(function (_a) {
            var title = _a[0], value = _a[1];
            return (React.createElement("div", { className: "btn-group-item", key: title },
                React.createElement("input", { type: "checkbox", className: "check", name: value, id: value, defaultChecked: props.pageS.stateFilter.get(value), onChange: filterStatus }),
                React.createElement("label", { className: "btn", htmlFor: "active" }, title)));
        }))));
}));
var HostsManagers = inject('store', 'instance')(observer(function (props) {
    var query = "inherits('std::system/App:1') AND std::system/App:1.applicationType == '".concat(HOST_APP_TYPE, "' AND isNotSet('std::types/Versionable:1.deletedAt')");
    var store = useState(VerTableStore.create({
        paginationDefaultRowsPerPage: 25,
        paginationRowsPerPageOptions: [25, 50, 100],
        selectable: false,
        query: query + (props.filter ? " AND search('".concat(props.filter, "')") : ''),
        columns: [
            ColumnModel.create({}),
            ColumnRecordLink.create({ name: 'Manager', urlRoute: 'records' }),
            ColumnText.create({ name: 'Description', key: 'std::system/App:1.description', opts: { grow: 2 } }),
            ColumnReferences.create({ name: 'Admins', keys: ['std::system/App:1.defaultAdmins'] }),
            ColumnTimestamp.create({ name: 'Updated', key: 'std::types/Versionable:1.updatedAt' }),
        ],
    }))[0];
    useEffect(function () {
        loadRecordsWithStatusStats(store);
        store.setUp({ instance: props.instance, transport: props.store.TransportLayer });
    }, []);
    useEffect(function () {
        var newQuery = query + (props.filter ? " AND search('".concat(props.filter, "')") : '');
        if (newQuery !== store.query) {
            store.setQuery(newQuery);
        }
    }, [props.filter]);
    return React.createElement(Table, { store: store });
}));
export default inject('instance')(observer(function (_a) {
    var instance = _a.instance;
    var pageS = useState(HostsManagersListS.create({}))[0];
    useEffect(function () {
        if (!instance.Applications.loading) {
            instance.Applications.fetch();
        }
    }, []);
    var deleteApplication = function () {
        instance.Applications.delete(pageS.confirmDeleteForID, function () { return pageS.setConfirmDeleteForID(); });
    };
    var pageContent = (React.createElement(React.Fragment, null,
        pageS.loaded && React.createElement(HostsManagers, { filter: pageS.nameFilter.value }),
        pageS.confirmDeleteForID && (React.createElement(ConfirmationAboutDeleteWindow, { onClose: pageS.setConfirmDeleteForID, onCancel: pageS.setConfirmDeleteForID, onDelete: deleteApplication }))));
    if (!instance.Applications.loaded) {
        pageContent = React.createElement(Spinner, null);
    }
    return (React.createElement(ContainerBox, null,
        React.createElement(PageHeader, { title: "Hosts Managers", documentTitle: "Hosts Managers" }, instance.amIAdmin && (React.createElement(InstanceLink, { to: "/hosts-managers/launch", className: "btn btn-primary" },
            React.createElement(Icon, { className: "create-new-btn" }),
            "Create new"))),
        React.createElement("div", { className: "filters-box" },
            React.createElement(NameFilter, { pageS: pageS }),
            React.createElement(StateFilter, { pageS: pageS })),
        pageContent));
}));
