var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { cast, types } from 'mobx-state-tree';
import Store from 'stores/Store';
var ScriptEntity = types
    .model('ScriptEntity', {
    recordID: types.identifier,
    name: types.string,
})
    .volatile(function () { return ({
    _record: null,
}); })
    .actions(function (self) { return ({
    setRecord: function (record) {
        self._record = record;
    },
}); })
    .views(function (self) { return ({
    canBeDelete: function () {
        return self._record !== null;
    },
    get language() {
        if (self._record && self._record.loaded) {
            return self._record.script_1.syntax;
        }
        return '';
    },
    get author() {
        if (self._record && self._record.loaded) {
            return self._record.data.get('std::types/Auditable:1').get('who');
        }
        return '';
    },
    get updatedAt() {
        if (self._record && self._record.loaded) {
            return self._record.updatedAt;
        }
        return '';
    },
    get scriptType() {
        if (self._record && self._record.loaded) {
            var command = self._record.script_1.command[0];
            var commandWithoutCLIArguments = command.split(' ', 1)[0];
            var commandWithoutFullPath = commandWithoutCLIArguments.split('/').pop();
            return commandWithoutFullPath;
        }
        return null;
    },
}); });
var Dir = types
    .model('Dir', {
    absPath: types.string,
    scripts: types.array(ScriptEntity),
    dirs: types.array(types.string),
})
    .actions(function (self) { return ({
    saveDirsAndScripts: function (dirs, scripts) {
        self.dirs = cast(dirs);
        self.scripts = cast(scripts);
    },
}); });
var tableHeaders = [
    { itemKey: 'itemType', empty: true, value: '' },
    { value: 'name' },
    { value: 'type' },
    { value: 'author', title: 'Author' },
    { value: 'updatedAt', title: 'Updated at' },
];
var ScriptsPage = types
    .model('ScriptsPage', {
    scriptToDelete: types.maybeNull(types.reference(ScriptEntity)),
    loading: false,
    currentDirPath: '/',
    sortByField: 'name',
    reverseOrder: false,
    tree: types.map(Dir),
})
    .volatile(function () { return ({
    instance: null,
}); })
    .views(function (self) { return ({
    isRootDir: function () {
        return self.currentDirPath === '/';
    },
    get parentDir() {
        if (!this.isRootDir()) {
            return "".concat(self.currentDirPath.split('/').slice(0, -2).join('/'), "/");
        }
        return self.currentDirPath;
    },
    absPath: function (dirName) {
        return "".concat(self.currentDirPath).concat(dirName, "/");
    },
    get userIsAdmin() {
        if (self.instance) {
            return self.instance.amIAdmin;
        }
        return false;
    },
    get tableHeaders() {
        // TODO(andreykurilin): check here whether any of script is owned by
        //    current user to decide whether we can add delete button
        if (this.userIsAdmin) {
            return __spreadArray(__spreadArray([], tableHeaders, true), [{ empty: true, itemKey: 'delete', value: '' }], false);
        }
        return tableHeaders;
    },
}); })
    .actions(function (self) { return ({
    linkInstanceStore: function (store) {
        self.instance = store;
    },
    parseTree: function (dir, socketResult) {
        var _this = this;
        self.loading = false;
        var dirs = Object.keys(socketResult.dirs);
        self.instance.InventoryRecords.getMultiByIDs(Object.values(socketResult.scripts));
        var scripts = Object.entries(socketResult.scripts).map(function (_a) {
            var scriptName = _a[0], recordId = _a[1];
            var script = ScriptEntity.create({ name: scriptName, recordID: recordId });
            script.setRecord(self.instance.InventoryRecords.getById(recordId));
            return script;
        });
        dir.saveDirsAndScripts(dirs, scripts);
        Object.values(socketResult.dirs).forEach(function (dirSpec) {
            if (!dirSpec.abs_path) {
                return;
            }
            var innerDir = Dir.create({ absPath: dirSpec.abs_path });
            _this.parseTree(innerDir, dirSpec);
            self.tree.set(dirSpec.abs_path, innerDir);
        });
    },
    fetchTreeForCurrentDir: function () {
        var _this = this;
        self.loading = true;
        if (!self.tree.has(self.currentDirPath)) {
            self.tree.set(self.currentDirPath, Dir.create({ absPath: self.currentDirPath }));
        }
        /* we should do trimming probably in backend */
        var trimedCurDir = (self.currentDirPath.slice(-1) === '/' && self.currentDirPath.length > 1 && self.currentDirPath.slice(0, -1)) ||
            self.currentDirPath;
        self.instance.TransportLayer.post({
            url: '/i/api/v1/scripts/tree',
            body: {
                root: trimedCurDir,
                depth: 3,
            },
            onFailure: function (response, errors) {
                return Store.Notifications.error("Failed to retrieve scripts: ".concat(errors.join(';')));
            },
            onSuccess: function (response, responseData) {
                return _this.parseTree(self.tree.get(self.currentDirPath), responseData.data);
            },
        });
    },
    setCurrentDir: function (curDir) {
        if (self.currentDirPath !== curDir) {
            self.currentDirPath = curDir;
            this.fetchTreeForCurrentDir();
        }
        else if (curDir === '/' && !self.tree.has(curDir)) {
            // initial state
            this.fetchTreeForCurrentDir();
        }
    },
    setScriptToDelete: function (script) {
        self.scriptToDelete = script;
    },
    deleteScript: function () {
        var _this = this;
        if (self.scriptToDelete === null) {
            return;
        }
        var scriptName = self.scriptToDelete.name;
        var scriptID = self.scriptToDelete.recordID;
        self.scriptToDelete._record.script_1.delete().then(function (error) {
            _this.setScriptToDelete(null);
            if (error) {
                Store.Notifications.error("Failed to remove script ".concat(scriptName, ": ").concat(error));
            }
            else {
                var dir = self.tree.get(self.currentDirPath);
                dir.saveDirsAndScripts(dir.dirs, dir.scripts.filter(function (s) { return s.recordID !== scriptID; }));
                Store.Notifications.success("Script ".concat(scriptName, " is deleted."));
            }
        });
    },
    sortBy: function (sortField, reverseOrder) {
        self.sortByField = sortField;
        self.reverseOrder = reverseOrder;
    },
}); });
export default ScriptsPage;
