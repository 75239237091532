import React, { useState } from 'react';

import { inject, observer } from 'mobx-react';

import Icon from 'components/Icon';
import TerminalTab from 'pages/TerminalsModal/TerminalTab';

const getSessionTitle = (session) => {
  if (!session.loaded) {
    return 'loading...';
  }
  let title = `${session.accessUser}@${session.accessHost}`;
  if (session.xtermTitle) {
    title = ` ${title} (${session.xtermTitle})`;
  }
  return title;
};

const CloseSessionModal = observer((props) => {
  const [closeButtonActive, setCloseButtonActive] = useState(true);
  return (
    <div className="modal">
      <div className="modal-box">
        <div className="modal-header">
          <div className="title">Close</div>
          <div className="action" onClick={props.onCancel}>
            <Icon className="close-dark" />
          </div>
        </div>
        <div className="modal-body">{props.message}</div>
        <div className="modal-footer">
          <button className="btn-default" type="button" onClick={props.onCancel} disabled={!closeButtonActive}>
            Cancel
          </button>
          <button
            className="btn-primary"
            type="button"
            onClick={async () => {
              setCloseButtonActive(false);
              try {
                await props.onClose();
              } finally {
                setCloseButtonActive(true);
              }
            }}
            disabled={!closeButtonActive}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
});

const SessionTabTitle = observer((props) => {
  const terminals = props.terminalsStore;
  const session = props.session;
  const activeClass = terminals.isSelected(session) ? '-active' : '';
  return (
    <div
      className={`terminal-tab${activeClass}`}
      onClick={() => {
        terminals.selectSession(session);
      }}
    >
      <div className="terminal-tab-name" title={session.record.hostname}>
        {getSessionTitle(session)}
      </div>
      <Icon className="terminal-close-session" onClick={session.showConfirmClose} />
      {session.confirmCloseVisible && (
        <CloseSessionModal
          message={
            <p>
              Your terminal session on host <b>{session.accessHost}</b> will be closed.
            </p>
          }
          onCancel={() => {
            session.hideConfirmClose();
          }}
          onClose={async () => {
            await terminals.closeSession(props.session);
          }}
        />
      )}
    </div>
  );
});

const AdditionalSessionsList = observer((props) => {
  const terminals = props.terminalsStore;
  const [visible, setVisible] = useState(false);
  if (props.sessions.length === 0) {
    return null;
  }
  const items = props.sessions.map((session) => (
    <a
      onClick={() => {
        terminals.selectSession(session);
        setVisible(false);
      }}
    >
      {getSessionTitle(session)}
    </a>
  ));
  return (
    <>
      <Icon
        className="terminal-more-sessions"
        title="Show more sessions"
        onClick={() => {
          setVisible(true);
        }}
      />
      {visible && (
        <div className="hint">
          <div className="body">{items}</div>
        </div>
      )}
    </>
  );
});

const TerminalSessionsList = observer((props) => {
  const SESSIONS_LIST_SIZE = 15;
  const sessions = [];
  const additionalSessions = [];

  const store = props.terminalsStore;

  // TODO(e0ne): put active session into the 'sessions' list
  store.sortedSessions.forEach((session) => {
    if (store.isSelected(session)) {
      sessions.push(<SessionTabTitle key={session.id} session={session} terminalsStore={store} />);
    } else {
      if (sessions.length < SESSIONS_LIST_SIZE) {
        sessions.push(<SessionTabTitle key={session.id} session={session} terminalsStore={store} />);
      } else {
        additionalSessions.push(session);
      }
    }
  });

  return (
    <>
      {sessions}
      <div className="terminal-tab icon-box hint-container">
        <AdditionalSessionsList sessions={additionalSessions} terminalsStore={store} />
      </div>
    </>
  );
});

export const TerminalsModal = inject('store')(
  observer((props) => {
    const terminalS = props.store.Terminals;
    const style = {};

    if (!terminalS.modal.visible) {
      style.display = 'none';
    }

    return (
      <div className="global-modal" style={style}>
        <div className="terminal-grid">
          <div className="terminal-grid-header">
            <TerminalSessionsList terminalsStore={terminalS} />
            <div key="start-icon" className="terminal-tab icon-box" style={{ display: 'none' }}>
              <Icon className="terminal-new-session" title="Start new session" />
            </div>
            <div key="spacer" className="terminal-tab-spacer" />
            <div
              key="close-icon"
              className="terminal-tab icon-box"
              onClick={() => {
                terminalS.modal.hide();
              }}
            >
              <Icon className="terminal-hide" title="Hide terminals" />
            </div>
          </div>
          <div className="terminal-grid-body">
            <TerminalTab terminalsStore={terminalS} />
          </div>
        </div>
      </div>
    );
  })
);
