import React from 'react';
import Notifications from 'components/Notifications';
export default (function (props) { return (React.createElement("div", { className: "grid login" },
    React.createElement(Notifications, null),
    React.createElement("div", { className: "content" },
        React.createElement("div", { className: "container-login" },
            React.createElement("div", { className: props.loginBoxClassName ? "login-box ".concat(props.loginBoxClassName) : 'login-box' },
                React.createElement("div", { className: "logo" }),
                React.createElement("div", { className: "title" },
                    React.createElement("span", null, props.title)),
                props.children))),
    React.createElement("div", { className: "footer" },
        React.createElement("div", { className: "footer-login" },
            React.createElement("div", { className: "copyrights" }, "2024 \u00A9 Made with \u2764 in Kharkiv"))))); });
