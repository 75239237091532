import { getParentOfType, types } from 'mobx-state-tree';

import debounce from 'lib/debounce';

import { FilePathInputStore, InputStore } from 'components/forms/Input';
import { TerminalSession } from 'pages/TerminalsModal/TerminalsS';

const DownloadInput = FilePathInputStore.named('DownloadInput').views((self) => ({
  get finalInputClassName() {
    if (self.disabled) {
      return 'loading';
    }
    if (self.error.value) {
      return 'invalid';
    }
    return '';
  },
}));

const TerminalFileDownloader = types
  .model('TerminalFileDownloader', {
    path: types.maybeNull(types.string),
    exists: types.optional(types.boolean, false),
    mimeType: types.maybeNull(types.string),
    bytes: types.optional(types.maybeNull(types.integer), null),
    form: types.optional(DownloadInput, () => DownloadInput.create({})),
  })
  .views((self) => ({
    get error() {
      return self.form.error.value;
    },
    get session() {
      return getParentOfType(self, TerminalSession);
    },
    get record() {
      return self.session.record;
    },
    get connection() {
      return getParentOfType(self, TerminalSession).connection;
    },
    get terminalSocket() {
      return self.connection.addons.getTerminalAddon().socket;
    },
    get canDownload() {
      return self.exists;
    },
    stringToArrayBuffer: (byteString) => {
      const byteArray = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        byteArray[i] = byteString.codePointAt(i);
      }
      return byteArray;
    },
  }))
  .volatile(() => ({
    content: null,
  }))
  .actions((self) => ({
    setContent: (content) => {
      self.content = content;
    },
    setFileInfo: ({ info, error, path }) => {
      self.path = path;
      if (info === null) {
        self.exists = false;
        self.mimeType = null;
        self.bytes = null;
        self.form.setError(error);
      } else {
        if (!info.exists) {
          self.form.setError('File not found');
        } else {
          self.form.setError(undefined);
          self.mimeType = info.mime_type;
          // not sure why we are returning strings for bytes
          self.bytes = parseInt(info.bytes, 10);
        }
        self.exists = info.exists;
      }
    },
    downloadFile: async () => {
      self.content = null;
      self.form.setDisabled(true);
      let fileData = '';
      const fileSocket = await (
        await self.session.app
      ).socket('file:download', {
        onData: (data) => {
          if (data) {
            fileData += atob(data);
          } else {
            const blob = new Blob([self.stringToArrayBuffer(fileData)], { type: self.mimeType });
            self.setContent(URL.createObjectURL(blob));
          }
        },
      });

      await fileSocket.rpc('init', {
        id: self.session.target.id,
        source: self.path,
      });
    },
    updatePath: (path) => {
      const fetchData = async () => {
        if (!path.startsWith('/')) {
          const dir = await self.terminalSocket.rpc('cwd');
          if (path.startsWith('./')) {
            path = path.slice(2);
          }
          path = `${dir.cwd}/${path}`;
        }
        const info = await self.terminalSocket.rpc('file', {
          path: path,
        });
        self.setFileInfo({ info: info, path: path });
      };
      fetchData().catch((err) => {
        console.log(err.stack);
        self.setFileInfo({ info: null, error: err.toString(), path: path });
      });
    },
    afterAttach: () => {
      self.form.registerOnChangeHandler(
        debounce((name) => {
          self.updatePath(name);
        }, 255)
      );
    },
  }));

const TerminalFileUploader = InputStore.named('FileUploadInputStore')
  .props({
    // show progress bar
    progress: types.optional(types.maybeNull(types.integer), null),
    // in ms
    delayBeforeResettingProgressAfterUpload: 1500,

    dragActive: false,
    // override parent's default values
    inputType: 'file',
  })
  .volatile(() => ({
    dragTarget: null,
  }))
  .views((self) => ({
    isInProgress() {
      return self.progress !== null && self.progress !== 100;
    },
    get inputStyle() {
      if (self.isInProgress()) {
        return { background: `linear-gradient(to right, #bed1c5 0%, #bed1c5 ${self.progress}%, #ffffff 0%)` };
      }
      return undefined;
    },
    get session() {
      return getParentOfType(self, TerminalSession);
    },
    get connection() {
      return getParentOfType(self, TerminalSession).connection;
    },
    get appConnection() {
      return self.connection.appConnection;
    },
    get terminalSocket() {
      return self.connection.addons.getTerminalAddon().socket;
    },
    get buttonLabel() {
      if (self.progress === null) {
        return 'Upload';
      }
      return `${self.progress}%`;
    },
  }))
  .volatile((self) => ({
    onChange: (_, e) => {
      self.currentFiles = e.target.files;
      e.preventDefault();
      if (e.target.files && e.target.files[0]) {
        self.handleFileChange(e.target.files);
      }
    },
  }))
  .actions((self) => ({
    onClick() {
      if (!self.isInProgress()) {
        // reset progress
        self.setProgress(null);
        if (self.timer) {
          clearTimeout(self.timer);
          self.timer = null;
        }
        self.inputRef.current.click();
      }
    },
    async handleFileChange(files) {
      self.progress = 0;
      if (files.length === 0) {
        return;
      }
      const reader = new FileReader();
      reader.onload = async () => {
        const info = await self.terminalSocket.rpc('cwd');
        const fileSocket = await (await self.session.app).socket('file:upload', {});
        await fileSocket.rpc('init', {
          id: self.session.target.id,
          destination: `${info.cwd}/${files[0].name}`,
        });

        const encodeData = (data) => {
          let binary = '';
          const bytes = new Uint8Array(data);
          const len = bytes.byteLength;
          for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          return btoa(binary);
        };

        const chunkSize = 63000;
        const size = reader.result.byteLength;
        let offset = 0;
        if (size > chunkSize) {
          do {
            // eslint-disable-next-line no-await-in-loop
            await fileSocket.send_stream(encodeData(reader.result.slice(offset, offset + chunkSize)));
            offset += chunkSize;
            self.setProgress((offset / size) * 100);
          } while (offset + chunkSize < size);
          await fileSocket.send_stream(encodeData(reader.result.slice(offset)));
        } else {
          await fileSocket.send_stream(encodeData(reader.result));
        }
        // send empty data as EOF
        await fileSocket.send_stream('');
        // At this point this uploads file in future it will be just waiting to upload it
        await fileSocket.rpc('commit', {});
        self.setProgress(100);
      };
      reader.readAsArrayBuffer(files[0]);
    },
    // triggers when file is dropped
    handleDrop(e) {
      e.preventDefault();
      e.stopPropagation();
      self.dragActive = false;
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        self.handleFileChange(e.dataTransfer.files);
      }
    },
    onDragOver(e) {
      if (!self.isInProgress()) {
        self.dragTarget = e.target;
        self.dragActive = true;
      }
      e.stopPropagation();
      e.preventDefault();
    },
    onDragLeaveCapture(e) {
      e.stopPropagation();
      e.preventDefault();
      if (e.target === self.dragTarget) {
        self.dragActive = false;
      }
    },

    setProgress(value) {
      self.disabled = true;
      self.progress = value ? Math.ceil(value) : null;
      if (value === 100) {
        self.inputRef.current.value = null;
        // hide progress after 1.5 second
        self.timer = setTimeout(self.setProgress, self.delayBeforeResettingProgressAfterUpload);
      } else {
        self.disabled = value !== null;
      }
    },
  }));

export { TerminalFileDownloader, TerminalFileUploader };
