import React, { useState } from 'react';

import { observer } from 'mobx-react';

import 'regenerator-runtime/runtime';

import { formatHostInfo } from 'lib/hosts';

import Icon from 'components/Icon';
import { FileDownloader, FileUploader } from 'pages/TerminalsModal/TerminalFileOperations';
import { TerminalSearch } from 'pages/TerminalsModal/TerminalSearch';
import { XTerm } from 'pages/TerminalsModal/XTerm';

const TerminalBody = observer((props) => {
  const dragOpts = {};

  if (props.session) {
    const { fileUploader } = props.session;
    dragOpts.onDrop = fileUploader.handleDrop;
    dragOpts.onDragOver = fileUploader.onDragOver;
    dragOpts.onDragEnterCapture = fileUploader.onDragOver;
    dragOpts.onDragLeave = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };
    dragOpts.onDragLeaveCapture = fileUploader.onDragLeaveCapture;
  }

  return (
    <div className="terminal-body-wrapper" {...dragOpts}>
      {props.children}
    </div>
  );
});

const TerminalHeader = observer((props) => {
  const { session } = props;
  const target = session ? session.target : null;

  const [showDownloader, setShowDownloader] = useState(false);

  if (!target || !target.loaded) {
    return (
      <div className="terminal-tools-container">
        <div className="terminal-session-info">loading...</div>
      </div>
    );
  }

  const { hostname, userAtIP } = formatHostInfo(
    target.data.get('std::host/Host:1'),
    target.data.get('std::host/Container:1')
  );

  return (
    <>
      <div className="terminal-tools-container">
        <div className="terminal-session-info">
          Connected to: {target.container_1 && 'container'}
          {hostname} <span>{userAtIP}</span>
        </div>
        <div className="spacer" />
        <div className="terminal-tools-container">
          <TerminalSearch session={session} />
          <button
            type="button"
            className="btn btn-default btn-transparent btn-small download"
            onClick={() => setShowDownloader(true)}
          >
            <Icon className="terminal-download" />
            Download
          </button>
          <button
            type="button"
            className="btn btn-default btn-transparent btn-small upload"
            onClick={session.fileUploader.onClick}
          >
            <Icon className="terminal-upload" />
            {session.fileUploader.buttonLabel}
          </button>
        </div>
      </div>
      <div className="global-modals-placeholder">
        {showDownloader && (
          <FileDownloader fileDownloader={session.fileDownloader} onClose={() => setShowDownloader(false)} />
        )}
        <FileUploader fileUploader={session.fileUploader} onClose={() => setShowDownloader(false)} />
      </div>
    </>
  );
});

const TerminalTab = observer((props) => {
  const terminals = props.terminalsStore;
  const activeSession = terminals.selectedSession;

  const uploadHelper =
    activeSession && activeSession.fileUploader.dragActive ? (
      <div className="file-upload-zone" key="file-upload-zone">
        <div className="icon upload-file" />
        <p>To upload your file, please drag and drop it into this area.</p>
      </div>
    ) : null;

  return (
    <TerminalBody session={activeSession}>
      <TerminalHeader session={activeSession} />
      <div className="terminal-window">
        {uploadHelper}
        {terminals.sortedSessions.map((session) => (
          <XTerm key={session.sessionId} session={session} />
        ))}
      </div>
    </TerminalBody>
  );
});

export default TerminalTab;
