import React from 'react';
import { observer } from 'mobx-react';
export var SidedPanel = observer(function (_a) {
    var _b = _a.className, className = _b === void 0 ? '' : _b, children = _a.children;
    return (React.createElement("div", { className: "two-panels ".concat(className) }, children));
});
export var LeftPanel = observer(function (props) {
    return React.createElement("div", { className: "left" },
        " ",
        props.children,
        " ");
});
export var RightPanel = observer(function (props) {
    return React.createElement("div", { className: "right" },
        " ",
        props.children,
        " ");
});
