import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import Icon from 'components/Icon';
import { InstanceLink } from 'components/Nav';
import { ContainerBox, PageHeader } from 'components/Page';
import { Table } from 'components/table/Table';
import { ColumnCustom, ColumnLabels, ColumnModel, ColumnNewSession, ColumnRecordLink, ColumnReferences, ColumnTimestamp, loadRecordsWithStatusStats, VerTableStore, } from 'components/table/TableS';
export default inject('store', 'instance')(observer(function (props) {
    var query = "inherits('std::host/Host:1') AND isNotSet('std::types/Versionable:1.deletedAt')";
    var store = useState(VerTableStore.create({
        paginationDefaultRowsPerPage: 25,
        paginationRowsPerPageOptions: [25, 50, 100],
        selectable: false,
        query: query,
        columns: [
            ColumnModel.create({}),
            ColumnNewSession.create({}),
            ColumnRecordLink.create({
                name: 'Name',
                sortKey: 'std::types/Inventory:1.displayName',
            }),
            ColumnCustom.create({ name: 'Access' }).setRender(function (row) {
                var host = row.data.get('std::host/Host:1');
                return "".concat(host.get('accessUser'), "@").concat(host.get('accessIP'));
            }),
            ColumnReferences.create({ name: 'Hosts Manager', keys: ['std::types/Root:1.app'] }),
            ColumnLabels.create({ name: 'Labels', key: 'std::types/Root:1.labels', isolatedSearch: true }),
            ColumnTimestamp.create({ name: 'Updated', key: 'std::types/Versionable:1.updatedAt' }),
        ],
    }))[0];
    useEffect(function () {
        loadRecordsWithStatusStats(store);
        store.setUp({ instance: props.instance, transport: props.store.TransportLayer });
    }, []);
    return (React.createElement(ContainerBox, null,
        React.createElement(PageHeader, { title: "Hosts" },
            React.createElement(InstanceLink, { to: "/hosts/register", className: "btn btn-primary" },
                React.createElement(Icon, { className: "create-new-btn" }),
                "Register new host")),
        React.createElement(Table, { store: store })));
}));
