var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import * as queryString from 'lib/query-string';
import { InstanceLink } from 'components/Nav';
export var getURLParams = function (query, page, sortBy, reverseOrder) {
    var params = {
        q: query,
        page: page || 1,
    };
    if (sortBy) {
        params.sortBy = sortBy;
    }
    if (reverseOrder !== undefined) {
        params.reverseOrder = reverseOrder;
    }
    return queryString.stringify(params);
};
export var RecordLink = inject('instance')(observer(function (props) {
    var record = useState(props.instance.InventoryRecords.getById(props.recordId))[0];
    var title;
    if (record.loaded && !record.notFound && record.inventory_1) {
        title = record.inventory_1.displayName;
    }
    else {
        title = record.id;
    }
    var to = props.to || "/records/".concat(props.recordId);
    var text = title;
    if (props.getText !== undefined) {
        text = props.getText(record);
    }
    var className;
    if (props.className === undefined) {
        className = "btn btn-text btn-primary ".concat(props.isUser ? 'btn-avatar' : 'btn-small');
    }
    else {
        className = props.className;
    }
    return (React.createElement(InstanceLink, { to: to, title: title, className: className }, text));
}));
export var UserLink = inject('instance')(observer(function (props) {
    var record = useState(props.instance.InventoryRecords.getById(props.recordId))[0];
    var title;
    var avatarLetters = '--';
    if (!record.loaded) {
        title = "Loading: ".concat(record.id);
    }
    else if (record.notFound) {
        title = "Not found: ".concat(record.id);
    }
    else {
        title = record.inventory_1.displayName;
        var firstName = record.data.get('std::system/User:1').get('firstName');
        var lastName = record.data.get('std::system/User:1').get('lastName');
        avatarLetters = "".concat(firstName.charAt(0)).concat(lastName.charAt(0));
    }
    return (React.createElement(InstanceLink, { to: "/records/".concat(record.id), title: title, className: "btn btn-text btn-primary btn-avatar" }, avatarLetters));
}));
export var InventorySearchLink = function (props) {
    var prefix;
    if (props.modelname && props.modelname.startsWith('std::host/Host:1')) {
        prefix = 'hosts';
    }
    else if (props.modelname && props.modelname.startsWith('std::code/Script:1')) {
        prefix = 'scripts';
    }
    else {
        prefix = 'search';
    }
    return React.createElement(InstanceLink, __assign({ to: "/".concat(prefix, "?").concat(getURLParams(props.query)) }, props));
};
