import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';

import 'regenerator-runtime/runtime';
import 'xterm/css/xterm.css';

import Icon from 'components/Icon';

import Store from 'stores/Store';

const NewTerminalSession = observer((props) => {
  const record = props.record;
  const isSupportedModel = record.data.has('std::host/Host:1') || record.data.has('std::host/Container:1');

  const recordStatus = record.statusable_1.status;
  const app = props.app;
  const appStatus = app && app.statusable_1.status;
  const title = props.title || 'New session';
  const className = props.className || 'btn btn-success';
  const [alwaysDisabled, setAlwaysDisabled] = useState(false);

  const [buttonActive, setButtonActive] = useState(!alwaysDisabled);

  useEffect(() => {
    if (isSupportedModel) {
      setAlwaysDisabled(recordStatus !== 'ok' || appStatus !== 'ok');
    }
  }, [recordStatus, appStatus]);

  if (!isSupportedModel) {
    return null;
  }

  return (
    <button
      type="button"
      disabled={alwaysDisabled || !buttonActive}
      className={className}
      onClick={async () => {
        setButtonActive(false);
        try {
          await Store.Terminals.startNewSession(record);
        } finally {
          setButtonActive(true);
        }
      }}
    >
      <Icon className="terminal-btn" />
      {title}
    </button>
  );
});

export { NewTerminalSession };
