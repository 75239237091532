import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { inject, observer } from 'mobx-react';
import { getParent, getType } from 'mobx-state-tree';

import copy from 'copy-to-clipboard';
import moment from 'moment';
import DataTable, { createTheme } from 'react-data-table-component';
import 'regenerator-runtime/runtime';

import * as queryString from 'lib/query-string';

import { JustADot, StatusChart, StatusToCls } from 'components/charts/StatusChart';
import Icon from 'components/Icon';
import { Spinner } from 'components/Loader';
import { InstanceLink } from 'components/Nav';
import Popover from 'components/Popover';
import {
  ColumnCustom,
  ColumnInventoryRecordStatus,
  ColumnLabels,
  ColumnModel,
  ColumnNewSession,
  ColumnRecordLink,
  ColumnReferences,
  ColumnText,
  ColumnTimestamp,
} from 'components/table/TableS';
import TabWithDetails from 'pages/Instances/RecordView/overview';
import ScriptRunLogTab from 'pages/Instances/RecordView/ScriptRunLogTab';
import TerminalSessionTab from 'pages/Instances/RecordView/TerminalSessionTab';
import { NewTerminalSession } from 'pages/TerminalsModal/NewTerminalSession';

import Store from 'stores/Store';

createTheme(
  'itlook',
  {
    background: {
      default: 'transparent',
    },
  },
  'dark'
);

const ExpandedComponent = inject('instance')(
  observer((props) => {
    const [record, setRecord] = useState(null);

    useEffect(() => {
      setRecord(props.instance.InventoryRecords.getById(props.data.uuid));
    }, []);

    let comp = TabWithDetails;

    if (record) {
      if (record.session_1) {
        comp = TerminalSessionTab;
      }
      if (record.script_run_1) {
        comp = ScriptRunLogTab;
      }
    }

    return (
      <>
        {!record && <Spinner />}
        {record && React.createElement(comp, { record: record, instance: props.instance, nested: true })}
      </>
    );
  })
);

const ModelIconRender = inject('store')(
  observer((props) => {
    const recordId = props.record.get('std::types/Root:1').get('id');

    const recordInventory = props.record.get('std::types/Inventory:1');
    const title = recordInventory ? recordInventory.get('displayName') : recordId.slice(0, 8);

    const display = props.short ? props.modelName.split('/').slice(-1) : props.modelName;
    if (!props.store.Models.loaded && !props.store.Models.loading) {
      props.store.Models.fetch();
    }
    let model = null;
    if (props.store.Models.loaded) {
      model = props.store.Models.getByIdentifier(props.modelName);
    }

    const sortMapModelName = (name) => {
      const prefix = {
        'std::types/Root:1': '0',
        'std::types/Versionable:1': '1',
        'std::types/Audit:1': '2',
        'std::types/Meta:1': '2',
        'std::types/Inventory:1': '2',
        'std::types/Journal:1': '2',
        'std::types/Auditable:1': '3',
      };
      return prefix[name] || '';
    };

    return (
      <Popover>
        <Popover.Trigger>
          <Link to={`/catalog/models/${props.modelName}`}>
            <div
              className={`entity-type entity-icon bg-${props.statusClass}`}
              style={{ mask: `url(${props.store.Models.getPicture(props.modelName)})` }}
            />
            {props.appIsUnavailable && <Icon className="icon status-danger-sticker" />}
            {!props.iconOnly && display}
          </Link>
        </Popover.Trigger>
        <Popover.Window title={title}>
          <div style={{ width: '280px' }}>
            <p>{recordInventory.get('description')}</p>

            <h5> Current status </h5>
            {props.statusComponent}

            {props.statusStatsComponent && (
              <div>
                <h5> Status over 24 hours </h5>
                {props.statusStatsComponent}
              </div>
            )}

            <div>
              <h5> ID </h5>
              <p title="Click to copy" style={{ cursor: 'pointer' }} onClick={() => copy(recordId)}>
                {recordId}
              </p>
            </div>

            {model && (
              <div>
                <h5> Model </h5>
                <Link to={`/catalog/models/${props.modelName}`}>
                  <div
                    className="entity-type entity-icon-small"
                    style={{ background: `url(${props.store.Models.getPicture(props.modelName)})` }}
                  />
                  {props.modelName}
                </Link>

                <h5>Description</h5>
                <p>{model.definition.obj.description}</p>
              </div>
            )}
            {model && model.parts.length > 1 && <h5>Inherits </h5>}
            {model &&
              model.parts
                .filter((el) => el !== props.modelName)
                .sort((a, b) => {
                  a = sortMapModelName(a);
                  b = sortMapModelName(b);

                  if (a > b) {
                    return 1;
                  }
                  if (a < b) {
                    return -1;
                  }
                  return 0;
                })
                .map((el) => (
                  <div style={{ clear: 'both' }} key={el}>
                    <Link to={`/catalog/models/${el}`}>
                      <div
                        className="entity-type entity-icon-small"
                        style={{ background: `url(${props.store.Models.getPicture(el)})` }}
                      />
                      {el.slice(el.lastIndexOf('/') + 1)}
                    </Link>
                  </div>
                ))}
          </div>
        </Popover.Window>
      </Popover>
    );
  })
);

const ColumnCustomRender = observer((props) => {
  return props.column.render(props.row, props.table);
});

const ColumnTextRender = observer((props) => {
  let data = props.row.data.get(props.column.key);
  if (props.column.key.includes('.')) {
    const [model, field] = props.column.key.split('.');
    data = props.row.data.get(model).get(field);
  }

  return <p>{props.column.modifier(data)}</p>;
});

const ColumnTimestampRender = observer((props) => {
  const [model, field] = props.column.key.split('.');
  const ts = props.row.data.get(model).get(field);

  return (
    <Popover>
      <Popover.Trigger>
        <p>{ts ? moment(ts).format(props.column.format) : props.column.notSetMsg}</p>
      </Popover.Trigger>
      <Popover.Window title="Timestamp">
        <p>{ts || props.column.notSetMsg}</p>
      </Popover.Window>
    </Popover>
  );
});

const ColumnRecordLinkRender = observer((props) => {
  const data = props.row.data;
  const recordId = data.get('std::types/Root:1').get('id');
  const inventory = data.get('std::types/Inventory:1');
  const title = inventory ? inventory.get('displayName') : recordId.slice(0, 8);

  return (
    <InstanceLink to={`/${props.column.urlRoute}/${recordId}`} title={title} key={recordId}>
      {title}
    </InstanceLink>
  );
});

const ColumnReferencesRender = observer((props) => {
  const data = [];

  props.column.keys.forEach((key) => {
    const [model, field] = key.split('.');
    const refModel = props.row.data.get(model);
    if (refModel) {
      const refData = refModel.get(field);
      if (refData) {
        data.push(...(Array.isArray(refData) ? refData : [refData]));
      }
    }
  });

  const resolved = props.table.data.resolved;
  return (
    <>
      {Array.from(data || []).map((refId) => {
        if (!resolved.get(refId)) {
          return null;
        }
        const refData = resolved.get(refId).data;
        const inventory = refData.get('std::types/Inventory:1');
        const title = inventory ? inventory.get('displayName') : refId.slice(0, 8);
        return (
          <div style={{ whiteSpace: 'nowrap', display: 'block' }} key={`${refId}`}>
            {/* <ModelIconRender modelName={refData.get('@model')} short iconOnly /> */}
            <InstanceLink to={`/records/${refId}`} title={title} style={{ display: 'inline-block' }}>
              {title}
            </InstanceLink>
          </div>
        );
      })}
    </>
  );
});

const ColumnModelRender = observer((props) => {
  const statusable = props.row.data.get('std::types/Statusable:1');
  const status = statusable.get('status');
  const statusDescription = statusable.get('statusDescription');
  const statusCls = StatusToCls[status];
  const recordId = props.row.data.get('std::types/Root:1').get('id');

  let statusStatsData = props.table.data.extra.get('statusStats');
  statusStatsData = statusStatsData && statusStatsData.toJSON()[recordId];

  const resolved = props.table.data.resolved;
  const appId = props.row.data.get('std::types/Root:1').get('app');
  const app = resolved.get(appId);
  const appType = app.data.get('std::system/App:1').get('applicationType');
  let appIsUnavailable = false;

  // in future probably should make this configurable
  const appDisplayName =
    {
      'std::Hosts:1': 'Host Manager',
      'std::System:1': 'System',
    }[appType] || appType;

  let statusComponent = (
    <p className={`text-${statusCls}`}>
      {status}: {statusDescription}
    </p>
  );

  let statusStatsComponent = statusStatsData && (
    <StatusChart status={status} statusDescription={statusDescription} data={statusStatsData.stats} />
  );

  if (app) {
    const appStatusable = app.data.get('std::types/Statusable:1');
    const appStatus = appStatusable.get('status');
    const appStatusDescription = appStatusable.get('statusDescription');
    if (appStatus !== 'ok') {
      appIsUnavailable = true;
      const appIsUnavailableSince = app.data.get('std::types/Versionable:1').get('updatedAt');

      // We should move this logic to backend probably.
      let adjustedStatusStats = null;
      if (statusStatsData) {
        // create deep copy
        adjustedStatusStats = JSON.parse(JSON.stringify(statusStatsData.stats));

        let i = statusStatsData.stats.length - 1;
        const timeFrameDuration = adjustedStatusStats[1].timestamp - adjustedStatusStats[0].timestamp;
        for (; i >= 0; i--) {
          if (adjustedStatusStats[i].timestamp < appIsUnavailableSince) {
            // during the time frame app become unavailable
            // we need to estimate % of time frame that app was unavailable
            // and remove it it from the very last record state of record
            const recordLastUpdate = props.row.data.get('std::types/Versionable:1').get('updatedAt');

            if (recordLastUpdate < adjustedStatusStats[i].timestamp) {
              adjustedStatusStats[i].stats = { unknown: 1 };
            } else {
              const addUnknown = 1 - (appIsUnavailableSince - adjustedStatusStats[i].timestamp) / timeFrameDuration;
              adjustedStatusStats[i].stats.unknown += addUnknown;
              adjustedStatusStats[i].stats[status] -= addUnknown;
            }
            break;
          }
          // these stats are happening after the app is down
          adjustedStatusStats[i].stats = { unknown: 1 };
        }
      }
      statusComponent = (
        <p className={`text-${StatusToCls[appStatus]}`}>
          <Icon className="status-danger" /> Unavailable!
          <br />
          {appDisplayName} has {appStatus} status: <br />
          {appStatusDescription}
        </p>
      );
      statusStatsComponent = adjustedStatusStats && (
        <StatusChart status={status} statusDescription={statusDescription} data={adjustedStatusStats} />
      );
    }
  }

  return (
    <ModelIconRender
      record={props.row.data}
      app={resolved.get(appId).data}
      appIsUnavailable={appIsUnavailable}
      statusIconClass={StatusToCls[status]}
      statusClass={StatusToCls[status]}
      statusComponent={statusComponent}
      statusStatsComponent={statusStatsComponent}
      modelName={props.row.data.get('@model')}
      short={props.column.short}
      iconOnly={props.column.iconOnly}
    />
  );
});

const ColumnLabelsRender = observer((props) => {
  const [model, field] = props.column.key.split('.');
  let data = props.row.data.get(model).get(field);
  data = Array.isArray(data) ? data : [data];

  return (
    <>
      {Array.from(data)
        .filter((e) => e)
        .map((label) => {
          const queryParams = queryString.stringify({
            q: props.column.isolatedSearch ? `${props.column.key}=='${label}'` : `search('${label}')`,
            page: 1,
          });

          return (
            <InstanceLink
              key={`/records/${label}`}
              to={`/search?${queryParams}`}
              title={label}
              className="btn btn-text btn-default btn-small btn-label"
            >
              {label}
            </InstanceLink>
          );
        })}
    </>
  );
});

const ColumnInventoryRecordStatusRender = observer((props) => {
  const statusable = props.row.data.get('std::types/Statusable:1');
  const status = statusable.get('status');
  const recordId = props.row.data.get('std::types/Root:1').get('id');
  const data = props.table.data.extra.get('statusStats').toJSON()[recordId];

  const resolved = props.table.data.resolved;
  const appId = props.row.data.get('std::types/Root:1').get('app');
  const app = resolved.get(appId);
  const appType = app.data.get('std::system/App:1').get('applicationType');

  // in future probably should make this configurable
  const appDisplayName =
    {
      'std::Hosts:1': 'Host Manager',
      'std::System:1': 'System',
    }[appType] || appType;

  if (app) {
    const appStatusable = app.data.get('std::types/Statusable:1');
    const appStatus = appStatusable.get('status');
    if (appStatus !== 'ok') {
      const lastUpdate = moment(props.row.data.get('std::types/Versionable:1').get('updatedAt')).format(
        'MMM D YYYY, HH:mm'
      );

      return (
        <Popover>
          <Popover.Trigger>
            <Icon className="icon status-danger" />
          </Popover.Trigger>
          <Popover.Window
            title={`${appDisplayName} is in ${appStatus} state: ${appStatusable.get('statusDescription')}`}
          >
            <p>
              <JustADot status={status} title={status} />
              {statusable.get('statusDescription')}
            </p>
            <p>Last status reported at {lastUpdate} </p>
            <p>It is impossible to determine the actual status of the resource at the moment</p>
          </Popover.Window>
        </Popover>
      );
    }
  }

  return (
    <div>
      {!props.row.active && <JustADot status={status} title={status} />}
      {props.row.active && data && (
        <StatusChart status={status} statusDescription={statusable.get('statusDescription')} data={data.stats} />
      )}
    </div>
  );
});

const ColumnNewSessionRender = observer((props) => {
  const recordRoot = props.row.data.get('std::types/Root:1');
  const record = Store.instance.InventoryRecords.getById(recordRoot.get('id'));
  const app = Store.instance.InventoryRecords.getById(recordRoot.get('app'));
  return <NewTerminalSession title=" " record={record} app={app} className="btn btn-icon" />;
});

const loadColumns = (columns) => {
  return columns.map((column) => {
    const component = {
      [ColumnCustom.name]: ColumnCustomRender,
      [ColumnText.name]: ColumnTextRender,
      [ColumnRecordLink.name]: ColumnRecordLinkRender,
      [ColumnReferences.name]: ColumnReferencesRender,
      [ColumnLabels.name]: ColumnLabelsRender,
      [ColumnTimestamp.name]: ColumnTimestampRender,
      [ColumnInventoryRecordStatus.name]: ColumnInventoryRecordStatusRender,
      [ColumnModel.name]: ColumnModelRender,
      [ColumnNewSession.name]: ColumnNewSessionRender,
    }[getType(column).name];

    return {
      name: column.name,
      selector: (row) => React.createElement(component, { column: column, row: row, table: getParent(column, 2) }),
      sortField: column.sortKey,
      sortable: column.sortKey !== null,
      ...column.opts.toJSON(),
    };
  });
};

const NoDataComponent = observer((props) => {
  if (props.error) {
    return (
      <div className="errorMessage">
        <p className="errorHeader">
          {props.error.status} ({props.error.code})
        </p>
        {props.error.details.map((el) => (
          <p key="el"> {el} </p>
        ))}
      </div>
    );
  }

  return <p>No records to display</p>;
});

export const Table = observer((props) => {
  const store = props.store;

  useEffect(() => {
    return store.stopAutoRefresh;
  }, []);

  return (
    <DataTable
      className="rdt_TopContainer"
      title={store.data.title}
      columns={loadColumns(store.columns)}
      data={store.rows}
      expandableRows
      expandableRowsComponent={ExpandedComponent}
      pagination
      paginationServer
      paginationServerOptions={{
        persistSelectedOnSort: true,
        persistSelectedOnPageChange: true,
      }}
      paginationPerPage={store.paginationDefaultRowsPerPage}
      paginationRowsPerPageOptions={store.paginationRowsPerPageOptions}
      onChangeRowsPerPage={store.changeRowsPerPage}
      paginationTotalRows={store.data.pagination.total}
      onChangePage={store.changePage}
      onSort={store.changeSort}
      sortServer
      selectableRows={store.selectable}
      noDataComponent={<NoDataComponent error={store.error} />}
      persistTableHead
      fixedHeader
      disabled={store.loading && store.inited}
      progressPending={!store.inited}
      progressComponent={<Spinner />}
      dense
      highlightOnHover
      onSelectedRowsChange={(selected) => store.changeSelected(selected.selectedRows)}
      selectableRowSelected={(row) => store.isSelectedRow(row)}
      onRowMouseEnter={(row) => {
        row.setActive(true);
      }}
      onRowMouseLeave={(row) => {
        row.setActive(false);
      }}
      theme="itlook"
    />
  );
});
