import variables from 'styles/_v.module.scss';

export const COLORS = [
  variables.chartBlue1,
  variables.chartMint,
  variables.chartViolet,
  variables.chartPurple,
  variables.chartBrown,
  variables.chartBlue2,
  variables.chartMint2,
  variables.chartViolet1,
  variables.chartPurple1,
  variables.chartBrown1,
];
