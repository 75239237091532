import React from 'react';
import { getParentOfType, types } from 'mobx-state-tree';
import { UUID4 } from 'lib/uuid-utils';
import Store from 'stores/Store';
var LogItem = types
    .model('JournalLogItem', {
    id: types.identifier,
    text: types.string,
    // stdout/stderr
    stream: types.string,
    _mounted: false,
})
    .volatile(function () { return ({
    ref: undefined,
}); })
    .views(function (self) { return ({
    get parent() {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        return getParentOfType(self, JournalLogsStore);
    },
}); })
    .actions(function (self) { return ({
    makeRef: function () {
        self.ref = React.createRef();
    },
    onComponentDidMount: function () {
        if (self.ref &&
            self.ref.current &&
            !self._mounted &&
            self.parent.autoScrollToTheEnd &&
            self.parent.lastItem === self) {
            self.ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
            self._mounted = true;
        }
    },
}); });
var JournalLogsStore = types
    .model('JournalLogsStore', {
    owner: types.string,
    inherits: types.string,
    autoScrollToTheEnd: types.maybeNull(types.boolean),
    cursor: 0,
    logs: types.maybeNull(types.array(LogItem)),
    watch: false,
    // property to check the direction of 'scroll' event to identify whether
    //  it is user-triggered event, so we should stop auto-scrolling to the end
    _scrollTopPosition: 0,
    // we need a flag to exit an infinite watcher for new logs
    _stop: false,
    // vars to prevent calling `onComponentDidMount` method twice (or more)
    _initLoadIsCalled: false,
    // ID element to attach listener of scroll events
    _elementID: types.optional(types.string, UUID4),
})
    .volatile(function () { return ({
    logItemRefs: {},
    postponedPuller: undefined,
}); })
    .actions(function (self) { return ({
    afterCreate: function () {
        if (self.autoScrollToTheEnd === null) {
            self.autoScrollToTheEnd = self.watch;
        }
    },
    onScroll: function () {
        // NOTE(andreykurilin): As result of current implementation of DOM/css,
        //  onScroll event is triggered on parent node.
        var elem = document.getElementById(self._elementID);
        if (elem === null || !elem.parentNode) {
            return;
        }
        var position = elem.parentNode.scrollTop;
        if (position >= self._scrollTopPosition) {
            self._scrollTopPosition = position;
        }
        else {
            self.autoScrollToTheEnd = false;
            window.removeEventListener('scroll', this.onScroll);
        }
    },
    setCursor: function (value) {
        self.cursor = value;
    },
    clearTimeout: function () {
        if (self.postponedPuller !== undefined) {
            clearTimeout(self.postponedPuller);
            self.postponedPuller = undefined;
        }
    },
    loadLogs: function () {
        this.clearTimeout();
        var query = {
            inherits: self.inherits,
            owner: self.owner,
            scan_field: 'std::types/Journal:1.order',
            size: 1000,
            scan_cursor: self.cursor,
        };
        Store.TransportLayer.get({
            url: "/i/api/v1/journal",
            query: query,
            // @ts-ignore: to make `onFetchLogSuccess` visible, it should be
            //  declared before `loadLogs`.
            onSuccess: self.onFetchLogSuccess,
        });
    },
    onFetchLogSuccess: function (response, responseData) {
        var _a;
        var _this = this;
        var previousCounter = self.logs ? self.logs.length - 1 : 0;
        var orderError = false;
        var nextCursor = responseData.data.next_scan_cursor;
        var newBatch = responseData.data.results
            .map(function (el, index) {
            if (orderError || previousCounter + index !== el['std::types/Journal:1'].order) {
                if (!orderError) {
                    // this is first error
                    nextCursor = previousCounter + index - 1;
                    nextCursor = nextCursor < 0 ? 0 : nextCursor;
                    orderError = true;
                }
                return null;
            }
            if (el['std::types/Journal:1'].isEOF) {
                self._stop = true;
            }
            var item = LogItem.create({
                id: el['std::types/Root:1'].id,
                text: el['std::log/Log:1'].text,
                stream: el['std::log/Log:1'].streamType,
            });
            if (index + 1 === responseData.data.results.length) {
                item.makeRef();
            }
            return item;
        })
            .filter(function (item) { return item !== null; });
        if (self.logs === null) {
            self.logs = newBatch;
        }
        else {
            (_a = self.logs).push.apply(_a, newBatch);
        }
        if (!self._stop) {
            if (responseData.data.has_more && !orderError) {
                this.setCursor(nextCursor);
                this.loadLogs();
            }
            else if (self.watch || orderError) {
                // Give the backend some time to collect more logs in a batch
                self.postponedPuller = setTimeout(function () {
                    if (responseData.data.results.length) {
                        _this.setCursor(nextCursor);
                    }
                    _this.loadLogs();
                }, 1000);
            }
        }
    },
    onComponentDidMount: function () {
        self._stop = false;
        this.loadLogs();
        window.removeEventListener('scroll', this.onScroll);
        // https://github.com/facebook/react/issues/5042#issuecomment-145317519
        window.addEventListener('scroll', this.onScroll, true);
    },
    onComponentWillUnmount: function () {
        self._stop = true;
        this.clearTimeout();
        window.removeEventListener('scroll', this.onScroll);
    },
}); })
    .views(function (self) { return ({
    get lastItem() {
        return self.logs && self.logs[self.logs.length - 1];
    },
    get completed() {
        return false;
    },
}); });
export default JournalLogsStore;
