import { isMobile } from 'react-device-detect';

const TERMINAL_FONT_SIZE = isMobile ? 10 : 16;

class ITLookTerminalAddon {
  // app is instance of Application from stores/Instances/Applications.jsx
  constructor(app) {
    this.app = app;
    this._disposables = [];
  }

  async initSocket({ onError = null, onClose = null } = {}) {
    this.socket = await this.app.socket('terminal2', { initAtStart: false, onError, onClose });
  }

  activate(terminal) {
    this.term = terminal;
    this._disposables.push(terminal.onData((data) => this.sendData(data)));
    this._disposables.push(terminal.onBinary((data) => this.sendBinary(data)));

    let dataReceived = false;

    this.socket._onData = (message) => {
      if (message === null) {
        return;
      }
      if (message[0] === 'o') {
        terminal.write(message.slice(1));
      } else if (message[0] === 'r') {
        const newSize = JSON.parse(message.slice(1));
        this.onResizeEvent(newSize.columns, newSize.rows);
      }
      if (!dataReceived) {
        dataReceived = true;
        setTimeout(() => this.resizeTerminal(), 25);
      }
    };
    this.socket.connect();
  }

  waitUntilActivated() {
    return this.socket.connected();
  }

  async connect({ sessionId, proposeDimensions, resizeTerminal }) {
    this.proposeDimensions = proposeDimensions;
    this.resizeTerminal = resizeTerminal;

    this._disposables.push({
      dispose: () => this.socket.dispose(),
    });

    await this.socket.rpc('init', {
      id: sessionId,
    });
  }

  async setTerminalSize(rows, cols) {
    await this.socket.rpc('resize', {
      width: cols,
      height: rows,
    });
  }

  onResizeEvent(cols, rows) {
    const dim = this.proposeDimensions();
    if (!dim) {
      return;
    }

    if (cols > dim.cols || rows > dim.rows) {
      // we have to adjust the font (reduce) to fit the thing
      this.term.options.fontSize = Math.floor(this.term.options.fontSize / Math.max(cols / dim.cols, rows / dim.rows));
    } else if (cols < dim.cols || rows < dim.rows) {
      this.term.options.fontSize = Math.min(
        Math.round(this.term.options.fontSize / Math.max(cols / dim.cols, rows / dim.rows)),
        TERMINAL_FONT_SIZE
      );
    }
    this.term.resize(cols, rows);
  }

  sendData(data) {
    this.socket.send_stream(data);
  }

  sendBinary(data) {
    const buffer = new Uint8Array(data.length);
    for (let i = 0; i < data.length; ++i) {
      buffer[i] = data.charCodeAt(i) & 255;
    }
    this.sendData(buffer);
  }

  // call this when term is disposed
  dispose() {
    this._disposables.forEach((d) => d.dispose());
    this._disposables.length = 0;
  }
}

export { ITLookTerminalAddon, TERMINAL_FONT_SIZE };
