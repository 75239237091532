// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ``, "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fontsUi": `"Inter",Helvetica,system-ui,sans-serif`,
	"fontsMono": `"Roboto Mono","Martian Mono","Menlo","Monaco","Consolas",monospace`,
	"headerbarHeight": `60px`,
	"sidebarWidth": `200px`,
	"rightbarWidth": `300px`,
	"blur": `6px`,
	"fontSize1": `clamp(1.563rem,.495vw + 1.429rem,1.875rem)`,
	"fontSize2": `clamp(1.375rem,.297vw + 1.295rem,1.563rem)`,
	"fontSize3": `clamp(1.125rem,.198vw + 1.072rem,1.25rem)`,
	"fontSize4": `clamp(.875rem,.396vw + .769rem,1.125rem)`,
	"fontSize5": `clamp(.625rem,.396vw + .519rem,.875rem)`,
	"fontSize6": `clamp(.5rem,.396vw + .394rem,.75rem)`,
	"background": `#1e293b`,
	"content": `#334155`,
	"contentSub": `#475569`,
	"text": `#cbd5e1`,
	"textSub": `#94a3b8`,
	"lineColor": `#475569`,
	"gray000": `#050709`,
	"gray010": `#0a0d11`,
	"gray020": `#0f141a`,
	"gray030": `#141a22`,
	"gray040": `#1f2733`,
	"gray050": `#293444`,
	"gray060": `#334155`,
	"gray070": `#405066`,
	"gray080": `#4d5f78`,
	"gray090": `#5b6e89`,
	"gray100": `#617692`,
	"tint000": `#687d9b`,
	"tint010": `#6e85a3`,
	"tint020": `#758cac`,
	"tint030": `#889cb7`,
	"tint040": `#9bacc3`,
	"tint050": `#afbcce`,
	"tint060": `#b8c4d4`,
	"tint070": `#c2ccda`,
	"tint080": `#cbd4df`,
	"tint090": `#d5dce5`,
	"tint100": `#e3e6e9`,
	"tint120": `rgba(227,230,233,.6509803922)`,
	"tint130": `rgba(227,230,233,.2196078431)`,
	"black": `#000`,
	"glass": `rgba(51,65,85,.8)`,
	"glassFF": `rgba(51,65,85,.85)`,
	"darkGlass": `rgba(30,41,59,.85)`,
	"white": `#fff`,
	"transparent": `hsla(0,0%,100%,0)`,
	"success": `#1a936f`,
	"danger": `#d05353`,
	"warning": `#eea243`,
	"unknown": `#b8bfc6`,
	"deleted": `#6e6e6e`,
	"archived": `#3a7ca5`,
	"mediumRed": `#d05353`,
	"lightRed": `#e89696`,
	"mediumOrange": `#d08b53`,
	"lightOrange": `#e7bd93`,
	"mediumYellow": `#d0a553`,
	"lightYellow": `#e8cf96`,
	"mediumGreen": `#1a936f`,
	"lightGreen": `#bed1c5`,
	"brightGreen": `rgb(243.7567567568,247.0432432432,244.9675675676)`,
	"mediumBlue": `#598ef3`,
	"lightBlue": `#96cee8`,
	"brightBlue": `rgb(242.0390625,248.9515625,252.1609375)`,
	"link": `#0099f7`,
	"linkHover": `rgb(43,174.3198380567,255)`,
	"linkActive": `hsl(210,28.5714285714%,-12.2549019608%)`,
	"navbarBg": `#1e293b`,
	"navbarBgHover": `#334155`,
	"navbarText": `#94a3b8`,
	"navbarTextHover": `#cbd5e1`,
	"sidebarText": `#cbd5e1`,
	"btnAnimationTime": `.2s`,
	"btnBorderRadius": `3px`,
	"btnDefaultColor": `#475569`,
	"btnDefaultColorHover": `rgb(81.2869318182,97.3153409091,120.2130681818)`,
	"btnDefaultText": `rgba(203,213,225,.8)`,
	"btnDefaultTextHover": `#cbd5e1`,
	"btnPrimaryColor": `#0099f7`,
	"btnPrimaryColorHover": `rgb(0,137.2044534413,221.5)`,
	"btnPrimaryText": `hsla(0,0%,100%,.8)`,
	"btnPrimaryText-hover": `#fff`,
	"btnSuccessColor": `#1a936f`,
	"btnSuccessColorHover": `rgb(22.1676300578,125.3323699422,94.6387283237)`,
	"btnSuccessText": `hsla(0,0%,100%,.8)`,
	"btnSuccessTextHover": `#fff`,
	"btnWarningColor": `#eea243`,
	"btnWarningColorHover": `rgb(235.8853658537,150.4317073171,43.6146341463)`,
	"btnWarningText": `hsla(0,0%,100%,.8)`,
	"btnWarningTextHover": `#fff`,
	"btnDangerColor": `#d05353`,
	"btnDangerColorHover": `hsl(0,57.0776255708%,52.0588235294%)`,
	"btnDangerText": `hsla(0,0%,100%,.8)`,
	"btnDangerTextHover": `#fff`,
	"formBorder": `rgb(79.6875,101.5625,132.8125)`,
	"formBorderHover": `rgb(100.9375,126.3125,162.5625)`,
	"formLabel": `#94a3b8`,
	"formPlaceholder": `#94a3b8`,
	"formPlaceholderDisabled": `hsl(215,20.2247191011%,55.0980392157%)`,
	"formTextDisabled": `hsl(215,20.2247191011%,55.0980392157%)`,
	"chartBlue": `#3e92cc`,
	"chartBlue1": `#357eb1`,
	"chartBlue2": `#2b6b97`,
	"chartBlue3": `#23587e`,
	"chartBread": `#d1b490`,
	"chartBread1": `#b59c7d`,
	"chartBread2": `#9b856a`,
	"chartBread3": `#816e57`,
	"chartBrown": `#a6633f`,
	"chartBrown1": `#905535`,
	"chartBrown2": `#7a472c`,
	"chartBrown3": `#653a23`,
	"chartGreen": `#4a7c59`,
	"chartGreen1": `#84b09c`,
	"chartGreen2": `#709685`,
	"chartGreen3": `#5c7d6e`,
	"chartMint": `#98cbb4`,
	"chartMint1": `#84b09c`,
	"chartMint2": `#709685`,
	"chartMint3": `#5c7d6e`,
	"chartOrange": `#ee7b30`,
	"chartOrange1": `#cf6a28`,
	"chartOrange2": `#b15a21`,
	"chartOrange3": `#934a1a`,
	"chartViolet": `#864db7`,
	"chartViolet1": `#7936ae`,
	"chartViolet2": `#682e97`,
	"chartViolet3": `#582580`,
	"chartPurple": `#bc5090`,
	"chartPurple1": `#a3447d`,
	"chartPurple2": `#8b396a`,
	"chartPurple3": `#732e57`,
	"chartRed": `#c14953`,
	"chartRed1": `#a73e47`,
	"chartRed2": `#8f343b`,
	"chartRed3": `#772a30`,
	"chartYellow": `#e4bc42`,
	"chartYellow1": `#c6a338`,
	"chartYellow2": `#a98b2f`,
	"chartYellow3": `#8d7325`,
	"chartOlive": `#498037`,
	"chartOlive1": `#4b723d`,
	"chartOlive2": `#3e6033`,
	"chartOlive3": `#334f29`,
	"chartGray": `#708080`,
	"chartGray1": `#607070`,
	"chartGray2": `#506060`,
	"chartGray3": `#405050`
};
export default ___CSS_LOADER_EXPORT___;
