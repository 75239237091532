import { cast, types } from 'mobx-state-tree';
import { UserRoles, UserStates } from 'Constants';
import { DropdownSelectorStore, EmailInputStore, InputStore, NoDisplayError } from 'components/forms/Input';
export default types
    .model('UserInviteForm', {
    email: types.optional(EmailInputStore, function () { return EmailInputStore.create({}); }),
    firstName: types.optional(InputStore, function () {
        return InputStore.create({ label: 'First name', error: NoDisplayError.create({}) });
    }),
    lastName: types.optional(InputStore, function () {
        return InputStore.create({ label: 'Last name', error: NoDisplayError.create({}) });
    }),
    role: types.optional(DropdownSelectorStore, function () {
        return DropdownSelectorStore.create({
            label: 'Role',
            options: Object.values(UserRoles).map(function (userRole) { return ({ value: userRole }); }),
            noDefaultPlaceholder: true,
            selected: UserRoles.USER,
        });
    }),
    errors: types.array(types.string),
    // the process of creating an user
    requesting: false,
})
    .volatile(function () { return ({
    usersStore: null,
    navigate: (function () { }),
}); })
    .actions(function (self) { return ({
    linkNavigator: function (navigate) {
        self.navigate = navigate;
    },
    linkUsersStore: function (store) {
        self.usersStore = store;
    },
    setRequestingFlag: function (value) {
        self.requesting = value;
        self.email.setDisabled(value);
        self.firstName.setDisabled(value);
        self.lastName.setDisabled(value);
        self.role.setDisabled(value);
    },
    setRequestError: function (errors) {
        self.errors = cast(errors);
        self.requesting = false;
    },
    onSuccess: function () {
        self.navigate('/organization/users');
    },
    onFailure: function (resp, errors) {
        this.setRequestError(errors);
        this.setRequestingFlag(false);
    },
    sendInvitation: function () {
        this.setRequestingFlag(true);
        if (self.usersStore) {
            self.usersStore.invite(self.email.value, self.firstName.value, self.lastName.value, self.role.selected, this.onSuccess, this.onFailure);
        }
    },
    validateEmailExistenceInTheSystem: function (value) {
        if (!value) {
            return undefined;
        }
        var newPromise = new Promise(function (resolve) {
            var onFetchComplete = function (user) {
                if (user !== undefined) {
                    if (user.state === UserStates.INVITED) {
                        resolve('The user with such email already invited.');
                    }
                    else {
                        resolve('The user with such email already exists.');
                    }
                }
                resolve(undefined);
            };
            if (self.usersStore) {
                self.usersStore.getFromAPI(value, onFetchComplete);
            }
        });
        return newPromise;
    },
    afterCreate: function () {
        self.email.addInputValidator(this.validateEmailExistenceInTheSystem);
    },
}); })
    .views(function (self) { return ({
    get filled() {
        return self.email.isDone() && self.firstName.isDone() && self.lastName.isDone();
    },
}); });
