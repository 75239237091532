import React from 'react';

import { inject, observer } from 'mobx-react';

import { SimpleTextViewer, Syntaxes } from 'components/forms/TextEditor';
import { LeftPanel, SidedPanel } from 'components/layout/Panels';
import { InstanceLink } from 'components/Nav';
import { ContainerBox, PageHeader } from 'components/Page';

const SSHConf = `Host *.free.itlook.com
    ProxyCommand openssl s_client -quiet -servername %h -connect ssh.free.itlook.com:443 2>/dev/null`;

const AccessExamples = `# As username you can use one of the following:
# * itlook's record id - can be host, container or existing running session
# * hostname
# * container name
# * username@hostname - in case if you have multiple users on same host withing same hosts manager

# As hostname you should use one of hosts managers domain
# * hosts_managers_name.$(itlook_instance_url)
# * hosts_managers_record_id.$(itlook_instance_url)

# Samples

ssh workbox@home.boris.itlook.com

# same but with record ids

ssh e226d892-8eb6-4fa5-bba5-dcda90497728@912cc43e-f16c-45d3-add6-945109f4898f.boris.itlook.com`;

export default inject('instance')(
  observer(() => {
    return (
      <ContainerBox>
        <PageHeader title="Native SSH Access" documentTitle="Native SSH" />
        <SidedPanel className="ansible">
          <LeftPanel>
            <form>
              <h4>1. Upload Your Public Key </h4>
              <p>
                This is your personal key, it system wide, and all hosts managers will use it to identify you and
                authorize action.
              </p>
              <InstanceLink
                to="/api/i-api#/Authentication/add_public_key_api_v1_auth_public_keys__key__post"
                className="btn btn-primary"
              >
                Upload public key
              </InstanceLink>

              <h4>2. Setup ssh proxy command (on your host)</h4>
              <p>
                To access any system you have to use special proxy command. We recommend configuring it once in
                ssh_config file
              </p>

              <div className="form-box">
                <SimpleTextViewer
                  key="installation-steps2"
                  label="setup ~/.ssh_config"
                  syntax={Syntaxes.bash}
                  filename="install-ansible.sh"
                  value={SSHConf}
                />
              </div>

              <h4>3. Access ITLook's managed hosts</h4>
              <div className="form-box">
                <SimpleTextViewer
                  key="installation-steps3"
                  label="SSH Access samples"
                  syntax={Syntaxes.bash}
                  value={AccessExamples}
                />
              </div>
            </form>
          </LeftPanel>
        </SidedPanel>
      </ContainerBox>
    );
  })
);
