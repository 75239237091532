import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { LoginActions } from 'Constants';
import * as queryString from 'lib/query-string';
import Input from 'components/forms/Input';
import Icon from 'components/Icon';
import PageLayout from 'pages/Auth/components/PageLayout';
import UserFirstLogin from 'pages/Auth/components/UserFirstLogin';
import LoginFormStore from 'pages/Auth/LoginS';
var OAuthProvider = inject('notifications')(function (_a) {
    var link = _a.link, name = _a.name, notifications = _a.notifications, store = _a.store;
    return (React.createElement("button", { type: "button", title: "Use oauth ".concat(name), className: "btn btn-default", onClick: function () { return store.redirectToOAuth2Provider(link, notifications); }, disabled: store.processing },
        React.createElement(Icon, { className: name.toLowerCase() }),
        name));
});
var OAuthLinks = function (_a) {
    var store = _a.store;
    return (React.createElement(React.Fragment, null,
        React.createElement("hr", null),
        React.createElement("div", { className: "login-services-container" },
            React.createElement(OAuthProvider, { key: "google", name: "Google", link: "".concat(process.env.OAUTH2_GOOGLE_URL, "?start_url=").concat(store.encodedRedirectUrl), store: store }),
            React.createElement(OAuthProvider, { key: "github", name: "GitHub", link: "".concat(process.env.OAUTH2_GITHUB_URL, "?start_url=").concat(store.encodedRedirectUrl), store: store }))));
};
var ResetPassword = function (_a) {
    var redirect_url = _a.redirect_url;
    var resetPasswordQuery = '';
    if (redirect_url) {
        var encodedRedirectUrl = encodeURIComponent(redirect_url);
        resetPasswordQuery = "?redirect_url=".concat(encodedRedirectUrl);
    }
    return (React.createElement("div", { className: "login-link-container" },
        React.createElement(Link, { to: "/reset-password".concat(resetPasswordQuery) }, "Forgot password?")));
};
var CredentialsForm = observer(function (_a) {
    var store = _a.store;
    if (store.firstLogin || store.mfa) {
        return null;
    }
    return (React.createElement(PageLayout, { title: "Sign in" },
        React.createElement("form", { className: "form-box", onSubmit: function (e) {
                e.preventDefault();
            } },
            React.createElement(Input, { key: "email", store: store.email }),
            React.createElement(Input, { key: "password", store: store.password }),
            React.createElement("div", { className: "message-placeholder text-danger" }, store.error),
            React.createElement("button", { type: "button", className: "btn-primary btn-block", onClick: store.login, disabled: !store.filled }, "Sign In"),
            React.createElement(OAuthLinks, { store: store }),
            !process.env.DISABLE_NOT_WORKING && React.createElement(ResetPassword, { redirect_url: store.redirectURL }))));
});
var TOTPCode = observer(function (_a) {
    var store = _a.store;
    if (!store.mfa) {
        return null;
    }
    return (React.createElement(PageLayout, { title: "Sign in" },
        React.createElement("form", { className: "form-box", onSubmit: function (e) {
                e.preventDefault();
            } },
            React.createElement(Input, { store: store.totpCode }),
            React.createElement("button", { type: "button", className: "btn-primary btn-block", onClick: store.submitTotpCode, disabled: store.processing || !store.totpCode.isDone() }, "Submit"))));
});
export default inject('store')(observer(function (_a) {
    var store = _a.store;
    var form = useState(LoginFormStore.create({}))[0];
    var navigate = useNavigate();
    var location = useLocation();
    useEffect(function () {
        form.registerURLPathUpdater(navigate);
        form.registerSetToken(store.Profile.setToken);
    }, []);
    useEffect(function () {
        var query = queryString.parse(location.search);
        form.setRedirectURL(query.redirect_url);
        if (query.action && LoginActions.hasOwnProperty(query.action)) {
            var action = LoginActions[query.action];
            store.Notifications.notify(action.msg, action.notificationType);
        }
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(UserFirstLogin, { store: form }),
        React.createElement(CredentialsForm, { store: form }),
        React.createElement(TOTPCode, { store: form })));
}));
