import { types } from 'mobx-state-tree';

import moment from 'moment';

import { bytesToString } from 'lib/string-utils';

import { JSONType } from 'components/table/mobxCommon';
import { fetchFieldStats } from 'components/table/StatsS';

const Pagintation = types.model('Pagination', {
  total: types.integer,
  start: types.integer,
  size_per_page: types.integer,
  page: types.integer,
  total_pages: types.integer,
});

const VersionableModel = types
  .model('VersionableModel', {
    uuid: types.identifier,
    data: types.map(JSONType),
    active: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get inherits() {
      return Object.keys(self.data).filter((el) => el.startsWith('@'));
    },
    get model() {
      return self.data.get('@model');
    },
  }))
  .actions((self) => ({
    setActive: (active) => {
      self.active = active;
    },
    setData: (data) => {
      self.data = data;
    },
  }));

const TableVersionableData = types
  .model('TableVersionableData', {
    rows: types.array(types.reference(VersionableModel)),
    resolved: types.map(types.reference(VersionableModel)),
    pagination: types.maybeNull(Pagintation),
    models: types.map(types.integer),
    extra: types.optional(JSONType, {}),
  })
  .actions((self) => ({
    initPagination: (sizePerPage) => {
      self.pagination = { total: 0, start: 0, size_per_page: sizePerPage, page: 1, total_pages: 0 };
    },
  }));

const OptionsForColumn = types.model('Options', {
  width: types.maybeNull(types.string),
  minWidth: types.maybeNull(types.string),
  maxWidth: types.maybeNull(types.string),
  grow: types.maybeNull(types.integer),
});

export const ColumnCustom = types
  .model('ColumnCustom', {
    name: types.string,
    sortKey: types.optional(types.maybeNull(types.string), null),
    opts: types.optional(OptionsForColumn, {}),
  })
  .volatile(() => ({
    render: (row, table) => `${row} ${table}`,
  }))
  .actions((self) => ({
    setRender: (render) => {
      self.render = render;
      return self;
    },
  }));

export const ColumnNewSession = types
  .model('ColumnNewSession', {
    name: types.optional(types.string, 'Connect'),
    sortKey: types.optional(types.maybeNull(types.string), null),
    opts: types.optional(OptionsForColumn, { maxWidth: '95px', minWidth: '95px' }),
  })
  .volatile(() => ({
    render: (row, table) => `${row} ${table}`,
  }))
  .actions((self) => ({
    setRender: (render) => {
      self.render = render;
      return self;
    },
  }));

const typeToRender = {
  none: (r) => r,
  bytes: bytesToString,
};

export const ColumnText = types
  .model('ColumnText', {
    name: types.string,
    key: types.string,
    unit: types.optional(types.union(types.literal('none'), types.literal('bytes')), 'none'),
    opts: types.optional(OptionsForColumn, {}),
  })
  .views((self) => ({
    get sortKey() {
      return self.key;
    },
  }))
  .volatile((self) => ({
    modifier: (record) => {
      return typeToRender[self.unit](record);
    },
  }))
  .actions((self) => ({
    setModifier: (modifier) => {
      self.modifier = modifier;
      return self;
    },
  }));

export const ColumnTimestamp = types
  .model('ColumnTimestamp', {
    name: types.string,
    key: types.string,
    format: types.optional(types.string, 'lll'),
    notSetMsg: types.optional(types.string, 'not set'),
    opts: types.optional(OptionsForColumn, { width: '210px' }),
  })
  .views((self) => ({
    get sortKey() {
      return self.key;
    },
  }));

export const ColumnRecordLink = types.model('ColumnRecordLink', {
  name: types.string,
  urlRoute: types.optional(types.string, 'records'),
  opts: types.optional(OptionsForColumn, {}),
  sortKey: types.optional(
    types.union(types.literal('std::types/Inventory:1.displayName'), types.literal('std::types/Root:1.id')),
    'std::types/Root:1.id'
  ),
});

export const ColumnReferences = types.model('ColumnReferences', {
  name: types.string,
  sortKey: types.optional(types.maybeNull(types.string), null),
  keys: types.array(types.string),
  opts: types.optional(OptionsForColumn, {}),
});

export const ColumnLabels = types
  .model('ColumnLabels', {
    name: types.string,
    key: types.string,
    opts: types.optional(OptionsForColumn, {}),
    isolatedSearch: false,
  })
  .views(() => ({
    get sortKey() {
      return null;
    },
  }));

export const ColumnModel = types.model('ColumnModel', {
  name: types.optional(types.string, ''),
  short: types.optional(types.boolean, true),
  iconOnly: types.optional(types.boolean, true),
  opts: types.optional(OptionsForColumn, { width: '60px' }),
});

// probably we should consider making this generic
// in future
export const ColumnInventoryRecordStatus = types
  .model('ColumnInventoryRecordStatus', {
    name: types.string,
    opts: types.optional(OptionsForColumn, {}),
  })
  .views(() => ({
    get sortKey() {
      return 'std::types/Statusable:1.status';
    },
  }));

export const TableLoadError = types.model('TableLoadError', {
  code: types.integer,
  status: types.string,
  details: types.array(types.string),
});

const transportInventorySearch = (transport, query, page, rowsPerPage) => {
  return new Promise((resolve, reject) => {
    const onReject = (response, errors) => {
      reject(
        TableLoadError.create({
          code: response.status,
          status: response.statusText,
          details: errors,
        })
      );
    };

    transport.get({
      url: `/i/api/v1/record/search`,
      query: {
        query: query,
        start: page * rowsPerPage,
        size: rowsPerPage,
        resolve_all: true,
      },
      onSuccess: (response, responseData) => resolve({ response: response, responseData: responseData }),
      onFailure: onReject,
      onCrash: (resp, error) => onReject(resp, [error.message]),
    });
  });
};

const instanceInventorySearch = (instance, query, page, rowsPerPage, sortColumn, sortDesc) => {
  return new Promise((resolve, reject) => {
    const onReject = (response, errors) => {
      reject(
        TableLoadError.create({
          code: response.status,
          status: response.statusText,
          details: errors || [],
        })
      );
    };

    instance.InventoryRecords.search(
      query,
      page * rowsPerPage,
      rowsPerPage,
      (response, responseData) => resolve({ response: response, responseData: responseData }),
      onReject,
      sortColumn,
      sortDesc,
      (resp, error) => onReject(resp, [error.message]),
      true
    );
  });
};

export const VerTableStore = types
  .model('Table', {
    title: types.maybeNull(types.string),
    autoRefreshEverySecond: types.maybeNull(types.optional(types.number, 3.5)),
    paginationDefaultRowsPerPage: types.optional(types.integer, 25),
    paginationRowsPerPageOptions: types.optional(types.array(types.integer), [25, 50, 100]),
    sortColumn: types.optional(types.string, 'std::types/Versionable:1.updatedAt'),
    sortDesc: types.optional(types.boolean, true),
    selectable: types.optional(types.boolean, false),
    loading: types.optional(types.boolean, true),
    inited: types.optional(types.boolean, false),
    data: types.optional(TableVersionableData, {}),
    error: types.optional(types.maybeNull(TableLoadError), null),
    query: types.optional(types.string, "inherits('std::types/Versionable:1')"),
    selectedRows: types.map(types.reference(VersionableModel)),
    cachedRows: types.map(VersionableModel),
    columns: types.array(
      types.union(
        ColumnText,
        ColumnRecordLink,
        ColumnReferences,
        ColumnTimestamp,
        ColumnInventoryRecordStatus,
        ColumnCustom,
        ColumnLabels,
        ColumnModel,
        ColumnNewSession
      )
    ),
    scheduledSetQueryID: types.optional(types.maybeNull(types.integer), null),
  })
  .volatile((self) => ({
    autoRefreshInt: null,
    transport: null,
    instance: null,
    apiLoad: async (query, page, rowsPerPage) => {
      if (self.instance) {
        return instanceInventorySearch(self.instance, query, page, rowsPerPage, self.sortColumn, self.sortDesc);
      }
      if (self.transport) {
        return transportInventorySearch(self.transport, query, page, rowsPerPage);
      }
      throw new Error('Neither instance nor transport were set');
    },
    apiProcessor: async ({ responseData }) => {
      const data = responseData.data;
      return {
        rows: data.matches.map((el) => self.ensureCachedRow(el)),
        resolved: Object.fromEntries(
          Object.values(data.resolved).map((el) => {
            const vmodel = self.ensureCachedRow(el);
            return [vmodel.uuid, vmodel];
          })
        ),
        pagination: data.pagination,
        models: data.models,
      };
    },
  }))
  .views((self) => ({
    get rows() {
      return self.data.rows.toJSON();
    },
    isSelectedRow: (row) => {
      return self.selectedRows.has(row.uuid);
    },
  }))
  .actions((self) => ({
    setUp({ transport, instance }) {
      self.transport = transport;
      self.instance = instance;
      self.load({ page: 0, rowsPerPage: self.paginationDefaultRowsPerPage });

      if (self.autoRefreshInt) {
        clearInterval(self.autoRefreshInt);
      }
      if (self.autoRefreshEverySecond) {
        self.autoRefreshInt = setInterval(() => self.load({ showLoading: false }), self.autoRefreshEverySecond * 1000);
      }
    },
    stopAutoRefresh() {
      if (self.autoRefreshInt) {
        clearInterval(self.autoRefreshInt);
      }
      self.autoRefreshInt = null;
    },
    setApiLoad: (cb) => {
      self.apiLoad = cb;
    },
    setApiProcessor: (cb) => {
      self.apiProcessor = cb;
    },
    setLoading: (value) => {
      self.loading = value;
    },
    setData: (value) => {
      self.data = value;
      self.error = null;
      self.inited = true;
      self.setLoading(false);
    },
    setError: (error) => {
      self.error = error;
      self.data = {};
      // this is needed for rendering purpouses
      self.data.initPagination(self.paginationDefaultRowsPerPage);
      self.inited = true;
      self.setLoading(false);
    },
    setQuery: (query) => {
      self.clearSelected();
      self.query = query;
      if (self.loading) {
        if (self.scheduledSetQueryID) {
          clearTimeout(self.scheduledSetQueryID);
        }
        self.scheduledSetQueryID = setTimeout(() => self.setQuery(query), 10);
      } else {
        self.load({ page: 0, rowsPerPage: self.data.pagination.size_per_page });
      }
    },
    load: async ({ page, rowsPerPage, showLoading = true } = {}) => {
      if (showLoading) {
        self.setLoading(true);
      }
      //
      try {
        self.setData(
          await self.apiProcessor(
            await self.apiLoad(
              self.query,
              page === undefined ? self.data.pagination.page - 1 : page,
              rowsPerPage || self.data.pagination.size_per_page
            )
          )
        );
      } catch (e) {
        self.setError(e);
      }
    },
    changeRowsPerPage: (rowsPerPage, page) => {
      self.load({ page: page - 1, rowsPerPage });
    },
    changePage: (page) => {
      self.load({ page: page - 1, rowsPerPage: self.data.pagination.size_per_page });
    },
    changeSort: (column, order) => {
      self.sortColumn = column.sortField;
      self.sortDesc = order === 'desc';
      self.load();
    },
    ensureCachedRow: (el) => {
      const uuid = el['std::types/Root:1'].id;

      if (!self.cachedRows.has(uuid)) {
        self.cachedRows.set(uuid, VersionableModel.create({ uuid: uuid, data: el }));
      } else {
        self.cachedRows.get(uuid).setData(el);
      }
      return self.cachedRows.get(uuid);
    },
    changeSelected: (selected) => {
      const selectedMap = new Map(selected.map((el) => [el.uuid, el]));
      self.data.rows.forEach((el) => {
        if (selectedMap.has(el.uuid)) {
          if (!self.selectedRows.has(el.uuid)) {
            self.selectedRows.set(el.uuid, el);
          }
        } else {
          if (self.selectedRows.has(el.uuid)) {
            self.selectedRows.delete(el.uuid);
          }
        }
      });
    },
    clearSelected: () => {
      self.selectedRows = {};
    },
    afterCreate: () => {
      self.data.initPagination(self.paginationDefaultRowsPerPage);
    },
  }));

export const loadRecordsWithStatusStats = async (table) => {
  /* Fetch status of std::types/Statusable:1.status and adds this info to row.statusStatus field */
  const defaultProcessor = table.apiProcessor;
  table.setApiProcessor(async (responseData) => {
    const results = await defaultProcessor(responseData);
    const fetchStatusFor = results.rows
      .map((row) => (row.data.get('std::types/Inventory:1') ? row.uuid : null))
      .filter((el) => el);

    const now = moment.utc();
    const stopTimestamp = now.valueOf();
    const startTimestamp = now.subtract(1, 'days').add(1, 'seconds').valueOf();
    const interval = 3600 * 1000; // 1 hour

    let stats;

    if (fetchStatusFor.length > 0) {
      try {
        stats = await fetchFieldStats({
          transport: table.transport,
          uuids: fetchStatusFor,
          fieldName: 'std::types/Statusable:1.status',
          stopTimestamp,
          startTimestamp,
          interval,
        });
      } catch (e) {
        console.log(`Failed to load mstats: '${e.details}'`);
      }
    }

    results.extra = { statusStats: stats || {} };
    return results;
  });
};
