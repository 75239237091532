import React, { useEffect, useRef } from 'react';

import { observer } from 'mobx-react';

import 'regenerator-runtime/runtime';

import Spinner from 'components/Loader';

const XTermConnecting = observer(() => {
  return <Spinner />;
});

const XTermError = observer((props) => {
  return (
    <div style={{ textAlign: 'center' }}>
      <p className="text-danger">Terminal Session Is Unavailable!</p>
      <p className="text-danger">{props.error}</p>
    </div>
  );
});

const XTerm = observer((props) => {
  const session = props.session;
  const connection = session.connection;
  const ref = useRef();

  useEffect(() => {
    if (ref.current && session.isSelected) {
      connection.ensureConnected();
    }
    if (connection.term && session.isSelected) {
      connection.term.focus();
    }
  }, [session.id, connection.loading, connection.term, ref, session.isSelected]);

  const readonly = connection.loading || connection.error;

  const style = {};
  if (!session.isSelected) {
    style.display = 'none';
  }

  return (
    <div className="terminal-window" style={style}>
      {connection.loading && <XTermConnecting />}
      {connection.error && <XTermError error={connection.errorMsg} />}
      <div
        ref={ref}
        id={session.id}
        style={{ height: '100%', width: '100%' }}
        className={(readonly && 'terminal-read-only') || ''}
      />
    </div>
  );
});

export { XTerm };
