import { types } from 'mobx-state-tree';
import { UserRoles } from 'Constants';
import Store from 'stores/Store';
var AccessSettings = types
    .model('AccessSettings', {
    OAuth2Enabled: types.optional(types.boolean, true),
    OAuth2Only: types.optional(types.boolean, false),
    wildcardDomains: types.optional(types.array(types.string), []),
    wildcardNewUserRole: types.optional(types.string, UserRoles.USER),
})
    .actions(function (self) { return ({
    saveValue: function (optionName, value, onSuccess, onFailure) {
        var optionURL = "/m/api/v1/v0-settings/access/".concat(optionName);
        var realOnSuccess = function (response, body) {
            Store.Notifications.success("Option '".concat(optionName, "' is successfully changed."));
            if (onSuccess) {
                onSuccess(response, body);
            }
        };
        if (typeof value === 'boolean' && value === false) {
            Store.TransportLayer.delete({
                url: optionURL,
                onSuccess: realOnSuccess,
                onFailure: onFailure,
            });
        }
        else {
            Store.TransportLayer.post({
                url: optionURL,
                body: value,
                onSuccess: realOnSuccess,
                onFailure: onFailure,
            });
        }
    },
    toggleOAuth2Enabled: function () {
        self.OAuth2Enabled = !self.OAuth2Enabled;
        if (!self.OAuth2Enabled && self.OAuth2Only) {
            self.OAuth2Only = false;
        }
        this.saveValue('oauth2-enabled', self.OAuth2Enabled);
    },
    toggleOAuth2Only: function () {
        if (!(!self.OAuth2Only && !self.OAuth2Enabled)) {
            self.OAuth2Only = !self.OAuth2Only;
            this.saveValue('oauth2-only', self.OAuth2Only);
        }
    },
    saveWildcardDomains: function (onSuccess, onFailure) {
        this.saveValue('wildcard-domains', self.wildcardDomains, onSuccess, onFailure);
    },
    addWildcardDomain: function (domain, onSuccess, onFailure) {
        self.wildcardDomains.push(domain);
        this.saveWildcardDomains(onSuccess, onFailure);
    },
    deleteWildcardDomain: function (index) {
        self.wildcardDomains.splice(index, 1);
        this.saveWildcardDomains();
    },
    setWildcardNewUserRole: function (role) {
        self.wildcardNewUserRole = role;
        this.saveValue('wildcard-new-user-role', { role: self.wildcardNewUserRole });
    },
}); });
var Settings = types
    .model('Settings', {
    access: types.optional(AccessSettings, function () { return AccessSettings.create({}); }),
    loading: types.optional(types.boolean, false),
    loaded: types.optional(types.boolean, false),
})
    .actions(function (self) { return ({
    setLoading: function (value) {
        self.loading = value;
    },
    finishLoading: function (response_data) {
        var settings = response_data.data;
        if (settings) {
            self.loaded = true;
            self.access = AccessSettings.create({
                OAuth2Enabled: settings.access.oauth2_enabled,
                OAuth2Only: settings.access.oauth2_only,
                wildcardDomains: settings.access.wildcard_domains,
                wildcardNewUserRole: settings.access.wildcard_new_user_role,
            });
        }
    },
    fetch: function () {
        var _this = this;
        self.loading = true;
        Store.TransportLayer.get({
            url: '/m/api/v1/v0-settings',
            onSuccess: function (response, response_data) { return _this.finishLoading(response_data); },
            onFinish: function () { return _this.setLoading(false); },
        });
    },
}); });
export default Settings;
