import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import Spinner from 'components/Loader';
var JournalLogLine = observer(function (_a) {
    var item = _a.item, index = _a.index;
    useEffect(function () {
        item.onComponentDidMount();
    }, []);
    return (React.createElement("div", { className: "log-item" },
        React.createElement("div", { className: "log-item-linenumber", ref: item.ref }, index + 1),
        React.createElement("div", { className: "log-item-message" }, item.text)));
});
var Logs = observer(function (_a) {
    var store = _a.store;
    if (store.logs === null) {
        return React.createElement(Spinner, null);
    }
    return (React.createElement(React.Fragment, null, store.logs.map(function (item, index) { return (React.createElement(JournalLogLine, { key: item.id, item: item, index: index })); })));
});
export default observer(function (_a) {
    var store = _a.store, stats = _a.stats;
    return (React.createElement("div", { className: "log-container", id: store._elementID },
        React.createElement("div", { className: "log-header" }, Object.entries(stats).map(function (_a) {
            var label = _a[0], value = _a[1];
            return (React.createElement("div", { key: label },
                React.createElement("strong", null,
                    label,
                    ":"),
                " ",
                value));
        })),
        React.createElement(Logs, { store: store })));
});
