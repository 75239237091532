import React from 'react';

export class DeadPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      info: null,
    };
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
      error: error,
      info: info.componentStack,
    });
  }

  render() {
    if (!this.state.hasError) {
      return this.props.children;
    }

    console.log(this.state.error, this.state.info.componentStack);

    const reportAndReload = () => {
      window.location.reload();
    };

    return (
      <div className="grid deploy">
        <div className="content">
          <div className="laptop-box">
            <div className="laptop-blinking" />
            <div className="laptop-dead" />
          </div>

          <div className="laptop-msg">
            <div className="text-warning"> Something went wrong. </div>
            <button type="button" className="btn btn-primary" onClick={reportAndReload}>
              Report and Reload
            </button>
          </div>
        </div>
      </div>
    );
  }
}
export default DeadPage;
