import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';

import CancelScriptRun from 'pages/Instances/RecordView/TopBarControls/CancelScriptRun';
import DeleteButton from 'pages/Instances/RecordView/TopBarControls/DeleteButton';
import EditButton from 'pages/Instances/RecordView/TopBarControls/EditButton';
import { NewTerminalSession } from 'pages/TerminalsModal/NewTerminalSession';

import Store from 'stores/Store';

export default observer((props) => {
  const [app, setApp] = useState(null);

  useEffect(() => {
    const appId = props.record.data.get('std::types/Root:1').get('app');
    Store.instance.InventoryRecords.getById(appId, (appRecord) => {
      setApp(appRecord);
    });
  }, []);

  return (
    <>
      <NewTerminalSession record={props.record} app={app} />
      <CancelScriptRun record={props.record} />
      <EditButton record={props.record} />
      <DeleteButton record={props.record} />
    </>
  );
});
