import { types } from 'mobx-state-tree';
import Store from 'stores/Store';
import Swagger from 'stores/Swagger';
export default types
    .model('OrganizationInfo', {
    name: types.maybeNull(types.string),
    displayName: types.maybeNull(types.string),
    owner: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.string),
    instance_status: types.maybeNull(types.string),
    Swagger: types.optional(Swagger, function () { return Swagger.create({ url: '/m/api/v1/swagger.json' }); }),
    loading: false,
})
    .actions(function (self) { return ({
    afterCreate: function () {
        self.Swagger.setTokenProvider(function () { return Store.Profile.token; });
    },
    setDisplayName: function (displayName) {
        self.displayName = displayName;
    },
    saveInfo: function (info) {
        // this is not needed now
        // self.id = info.id
        self.name = info.name;
        self.displayName = info.display_name;
        self.owner = info.admin;
        self.createdAt = info.created_at;
        self.instance_status = info.status;
    },
    stopLoading: function () {
        self.loading = false;
    },
    fetch: function () {
        var _this = this;
        self.loading = true;
        Store.TransportLayer.get({
            url: '/m/api/v1/account',
            onSuccess: function (response, body) {
                _this.saveInfo(body.data);
            },
            onFinish: this.stopLoading,
        });
    },
}); })
    .views(function (self) { return ({
    get loaded() {
        return self.name !== null;
    },
}); });
