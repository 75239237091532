import React, { useEffect, useState } from 'react';
export var ColumnWithIconItem = function (props) { return (React.createElement("div", { className: "details-item-box icon-box" },
    React.createElement("div", { className: "icon", style: { backgroundImage: "url(".concat(props.url, ")") } }))); };
export var Item = function (props) { return React.createElement("div", null, props.children); };
export var Column = function (_a) {
    var children = _a.children, _b = _a.large, large = _b === void 0 ? false : _b;
    return (React.createElement("div", { className: "details-item-box".concat(large ? ' description-box' : '') }, children));
};
var TIMEOUT_TO_EXPAND = 0.5 * 1000;
var TIMEOUT_TO_CLOSE = 0.2 * 1000;
export var Container = function (props) {
    var _a = useState({ closed: true, auto: Boolean(props.expandable) }), state = _a[0], setState = _a[1];
    var timer;
    var stopTimer = function () {
        if (timer) {
            clearTimeout(timer);
            timer = undefined;
        }
    };
    var toggleClosed = function () {
        setState(function (prevState) { return ({ closed: !prevState.closed, auto: false }); });
    };
    var expand = function () {
        stopTimer();
        if (state.closed) {
            setState({ closed: false, auto: true });
        }
    };
    var onMouseOver = function () {
        stopTimer();
        if (state.closed) {
            timer = setTimeout(expand, TIMEOUT_TO_EXPAND);
        }
    };
    var close = function () {
        stopTimer();
        if (state.auto && !state.closed) {
            setState({ closed: true, auto: true });
        }
    };
    var onMouseOut = function () {
        stopTimer();
        if (!state.closed) {
            timer = setTimeout(close, TIMEOUT_TO_CLOSE);
        }
    };
    useEffect(function () {
        return stopTimer;
    }, []);
    return (React.createElement("div", { className: "details-container".concat(state.closed ? '' : ' details-container-open'), onMouseOver: onMouseOver, onFocus: onMouseOver, onMouseOut: onMouseOut, onBlur: onMouseOut },
        props.children,
        props.expandable && (React.createElement("div", { className: "expander" },
            React.createElement("div", { className: "caret", onClick: toggleClosed })))));
};
