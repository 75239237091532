import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import * as queryString from 'lib/query-string';
import Input from 'components/forms/Input';
import Spinner from 'components/Loader';
import PageLayout from 'pages/Auth/components/PageLayout';
import UserFirstLogin from 'pages/Auth/components/UserFirstLogin';
import OAuth2S from 'pages/Auth/OAuth2S';
var InvitationForm = observer(function (_a) {
    var store = _a.store;
    return (React.createElement(PageLayout, { title: "Create Account" },
        React.createElement("form", { className: "form-box", onSubmit: function (e) {
                e.preventDefault();
            } },
            React.createElement("div", { className: "message-placeholder" }, "Welcome to ITLook Inc system. Please feel the form to finish setup of Account."),
            React.createElement(Input, { key: "firstName", store: store.firstName }),
            React.createElement(Input, { key: "lastName", store: store.lastName }),
            React.createElement(Input, { key: "email", store: store.email }),
            React.createElement(Input, { key: "password", store: store.password }),
            React.createElement("div", { className: "message-placeholder text-danger" }, store.error.formattedValue),
            React.createElement("button", { type: "button", className: "btn-primary btn-block", onClick: store.processInvitation, disabled: !store.filled || store.creating }, "Create"))));
});
export default inject('profile')(observer(function (_a) {
    var profile = _a.profile;
    var _b = useState(), form = _b[0], setForm = _b[1];
    var location = useLocation();
    var navigate = useNavigate();
    var params = useParams();
    useEffect(function () {
        var query = queryString.parse(location.search);
        var error = null;
        if (!params.provider || !query.code || !query.state) {
            error = 'Something is wrong. Please contact ITLook administrator.';
        }
        var newForm = OAuth2S.create({
            provider: params.provider || null,
            code: query.code,
            state: query.state,
            error: error,
        });
        newForm.registerURLPathUpdater(navigate);
        newForm.registerSetToken(profile.setToken);
        if (!newForm.error) {
            newForm.login();
        }
        setForm(newForm);
    }, []);
    if (form === undefined) {
        return null;
    }
    if (form.firstLogin) {
        return React.createElement(UserFirstLogin, { store: form });
    }
    if (form.invitation) {
        return React.createElement(InvitationForm, { store: form.invitation });
    }
    var item;
    if (form.error) {
        item = form.error;
    }
    else if (form.notRegisteredYet) {
        item = 'You do not have registered account at ITLook yet.';
    }
    else {
        item = React.createElement(Spinner, null);
    }
    return (React.createElement(PageLayout, { title: "OAuth2 callback" },
        React.createElement("div", { className: "form-box" },
            React.createElement("form", null,
                React.createElement("div", { className: "message-placeholder" }, item)))));
}));
